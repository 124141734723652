import React from 'react'

function HambergerIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width}
      height={props.width}
      className={props.className}
      viewBox="0 0 24 24"
    >
      <path d="M3 18h18v-2H3zm0-5h18v-2H3zm0-7v2h18V6z"></path>
    </svg>
  );
}

export default HambergerIcon