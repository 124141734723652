import React from 'react'
import icons from '../../utils/Icons'
import Heart from '../../assets/images/heart_suit_flat.svg'
import LOGO from '../../assets/images/CleFactura.png'




export default function Footer() {

  return (
    <>
      <footer className=" hidden md:flex flex-col gap-3 footer bg-gray-800 w-full text-gray-200">       
        <div className="  flex justify-between items-center">
          {/* <div className="flex items-center gap-2">
            <img src={LOGO} alt="Clè-Factura Logo" className="footer-logo" />
            <div className='flex gap-0.5 text-gray-200'>
              <h2 class="text-xl md:text-2xl font-primary-semibold">Clè-Factura</h2>
              <sup class="uppercase text-xs font-primary-medium"
              >TM </sup>
            </div>            
            
          </div>                       */}
          {/* <div>
            <p className="text-xs text-gray-600 ">Powered by</p>
            <h1 className="text-xl md:text-2xl font-gotham-semibold ">
              Tforte
            </h1>
          </div> */}
        </div> 
        {/* <p>Your companion in scaling your Business</p> */}

        <div class="text-white flex-grow">
              <div class="flex gap-2.5 items-center">
                <img src={LOGO} alt="Clè-Factura Logo"  className="footer-logo  cursor-pointer" onClick={() => window.location = "https://clefactura.tforte.in"}/>
                <div>
                  <div class="flex">
                    <h2 class="text-xl md:text-2xl font-primary-semibold">Clè-Factura</h2>
                    <sup class="uppercase text-xs font-primary-semibold"
                      >TM</sup
                    >
                  </div>
                  <p class="text-gray-200 font-primary-light text-xs">Your companion in scaling your Business</p>
                </div>
              </div>
            </div>



        <hr class="h-px bg-gray-500 mb-1 border-0" />
        <div className='flex items-center justify-between'>
          <div className='flex flex-col gap-3 mt-4 mb-8'>
            <div className='text-sm'>
                <p className="text-gray-200 font-primary-regular cursor-pointer" onClick={() => window.location = "https://tforte.in/privacy"}>
                  Privacy & Policy
                </p>
            </div> 
            <div className='text-sm'>
                <p className="text-gray-200 font-primary-regular cursor-pointer" onClick={() => window.location = "https://tforte.in/terms"}>
                  Terms & Service
                </p>
            </div> 
            <div className='text-sm'>
                <p className="text-gray-200 font-primary-regular">
                  Cancellation Policy
                </p>
            </div>
            <div className='text-sm'>
                <p className="text-gray-200 font-primary-regular">
                  Contact us
                </p>
            </div>
            <div className="text-sm cursor-pointer" onClick={() => window.location = "https://tforte.in/careers"}>
                <p className="text-gray-200 font-primary-regular">
                  Careers
                </p>
            </div>            
          </div> 
          <div>
            <div className='text-sm'>
              <p className="text-gray-200 font-primary-bold">
                For Enquiry
              </p>
            </div>
            <div className='text-sm'>
                <p className="text-gray-200 font-primary-regular cursor-pointer" onClick={() => window.location = "mailto://hello@clefactura.com"}>
                  hello@clefactura.com
                </p>
            </div>                      
          </div>
        </div>
                
                
          <div className="block text-center text-sm md:flex justify-center items-center font-primary-regular">
          <p className="text-gray-200 flex items-center justify-center">
            Made with
            <img src={Heart} alt="Love with Work" className="w-5 h-5 mx-1" />
            from Tamil Nadu.
          </p>                
        </div>
        <div className='text-xs md:text-sm text-center'>
            <p className="text-gray-200 place-self-center font-primary-regular">
            &#169; 2024 Tforte Software. All rights reserved.
          </p>
          </div>
      </footer>
    </>
  );
}

