const CONFIG = {
    KEY:"8323b302-20b1-4b52-92cd-2467818594d8",
    TOKEN:"7f747c7d-4cee-4513-87b2-ee231441e6b4",
    URL:"https://192.168.1.12:443/CleFactura/",
    TENANT_ID:"defaultdb",
    DEFAULT_TENANT:"clefactura",
    // URL:"https://cle-factura-image-hub-429893815831.asia-south1.run.app/CleFactura/"

    // KEY: "78b848c8-5b80-4205-a6c6-2eed8bc6c8a7",
    // TOKEN: "9c11bf45-914e-403d-be4f-c9f37e4eb6df",
    // // // URL:"http://192.168.43.51/CleFactura/",
    // URL:"http://192.168.1.4/CleFactura/"
}
export default CONFIG
