import React from 'react'
import Star from '../../utils/icons/Star';
import { useNavigate } from 'react-router-dom';
import { useClient } from '../../utils/context/ClientContext';
import {encryptData} from "../../utils/EncryptionUtil";
import LocationIcon from "../../utils/icons/LocationIcon";

function ClientItem({ client }) {
  const clientContext = useClient();
  const navigate = useNavigate();
  const handleRedirect = () => {    
    clientContext.setTenant(encryptData(client.tenantId));
    clientContext.setName(client.name);
    clientContext.setClient(client);
    navigate("/home");
  }
  return (
      <div className="w-full group rounded-2xl shadow-md border border-gray-200 flex flex-col cursor-pointer justify-between">
        <div className="transition duration-300 ease-in-out">
          <img
              src={client.imageUrl}
              alt=""
              className="w-full rounded-t-2xl h-32 md:h-44 object-cover"
          />
          <div className="px-4 py-2 text-gray-700 flex flex-col gap-1">
            <p className="text-base font-primary-semibold text-gray-800 overflow-hidden line-clamp-3">
              {client.name}
            </p>
            <p className="font-primary-medium text-gray-600 text-sm line-clamp-2 overflow-hidden">
              {client.description}
            </p>
            <div className="flex justify-start items-center gap-1">
              <Star className="text-yellow-400 w-4 h-4"/>
              <p className="font-primary-semibold text-sm">
                {client.rating}
              </p>
              <span className="text-gray-600 text-sm">
              (450 Ratings)
            </span>
            </div>
            <p className="line-clamp-1 text-sm font-primary-medium text-gray-950 mt-2">
              Ph: {client.contactNumber}
            </p>
          </div>
        </div>
        <div className='px-4 pb-2 flex justify-between gap-2'>
          <button className='bg-primary rounded-lg font-primary-medium text-white my-2 mx-auto text-sm w-full'
                  onClick={handleRedirect}>
            Proceed
          </button>
          <div>
            <button
                className='font-primary-bold text-white my-2 mx-auto w-fit border rounded-lg p-1 border-primary'
                onClick={handleRedirect}>
              <LocationIcon className="w-6 h-6 text-primary p-0.5 rounded-md fill-current"/>
            </button>
          </div>
        </div>
      </div>
  );
}

export default ClientItem