import React from 'react'

function AiIcon(props) {
    return (<svg width={props.width} height={props.height} className={props.className} viewBox="0 0 24 24" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
            <path fill="currentColor"
                  d="M10 14.175L11 12l2.175-1L11 10l-1-2.175L9 10l-2.175 1L9 12l1 2.175ZM10 19l-2.5-5.5L2 11l5.5-2.5L10 3l2.5 5.5L18 11l-5.5 2.5L10 19Zm8 2l-1.25-2.75L14 17l2.75-1.25L18 13l1.25 2.75L22 17l-2.75 1.25L18 21Zm-8-10Z"/>
        </svg>)
}

export default AiIcon