import { createContext, useContext, useEffect, useState } from "react";

const CartContext = createContext();

export const CartContextProvider = ({children}) => {
    const [cartUpdated, setCartUpdated] = useState(false);
    const [cartItems, setCartItems] = useState(() => {
        const cartItems = sessionStorage.getItem('cartItems');
        if(cartItems !== undefined && cartItems !== null){
            return JSON.parse(cartItems);
        }else{
            return null;
        }
    });  
    const [offerData, setOfferData] = useState(() => {
        const offerData = sessionStorage.getItem('offerData');
        if(offerData !== undefined && offerData !== null){
            return JSON.parse(offerData);
        }else{
            return null;
        }
    });        
    
    useEffect(() => {        
        sessionStorage.setItem('cartItems', JSON.stringify(cartItems));
        sessionStorage.setItem('offerData', JSON.stringify(offerData));
    }, [cartItems, offerData, cartUpdated]);

    const setCart = (products) => {
        setCartItems(products);
    }

    const addItem = (product) => {     
        if(cartItems !== null && cartItems !== undefined){
            if(cartItems.length > 0){
                let existingCartItems = cartItems;
                existingCartItems.push(product);
                setCartItems(existingCartItems);  
                setCartUpdated(!cartUpdated);
            }
        }
    }
    
    const setOffer = (offer) => {
        setOfferData(offer);
    }

    const resetCart = () => {
        sessionStorage.removeItem('cartItems');
        setCartItems(null);
    }

    const resetOffer = () => {
        sessionStorage.removeItem('offerData');
        setOfferData(null);
    }


    return <CartContext.Provider value={{cartItems, offerData, cartUpdated, setCart, setOffer, resetCart, resetOffer, addItem}}>{children}</CartContext.Provider>
}

export const useCart = () => {
    return useContext(CartContext);
}
