import React from 'react'
import Offer from '../../utils/icons/Offer'
import Copy from '../../assets/images/CopyIcon.svg'
import { useCart } from '../../utils/context/CartContext';

const OfferCard = ({ offerData, closeModal }) => {
  const cartContext = useCart();

  const handleOffer = () => {
    cartContext.setOffer(offerData);
    closeModal();
  }

  return (
    <>
      <div className="mx-auto h-auto w-full md:w-fit bg-gradient-to-r from-rose-100 to-teal-100 rounded-2xl shadow-lg p-5 border  text-gray-800">
        <Offer className=" w-16 h-16 fill-current mx-auto " />
        <h1 className="text-xl md:text-2xl font-primary-bold  text-center">
          {offerData !== undefined && offerData !== null && offerData.discount !== null && offerData.discount !== undefined ? offerData.discount : ""}% Off
          <span className="text-base font-primary-semibold md:font-primay-bold ">
            &nbsp;on orders above ₹{offerData !== undefined && offerData !== null && offerData.minimumAmount !== null && offerData.minimumAmount !== undefined ? offerData.minimumAmount : ""}
          </span>
        </h1>
        {/* <div className="text-gray-700 font-primary-semibold text-center my-1 mx-1">
          Coupon Code
        </div> */}
        <div className="text-sm px-2 text-nowrap font-primary-semibold flex justify-between items-center gap-4 w-fit mx-auto my-3 border-2 border-primary-blue border-dashed">
          {offerData !== undefined && offerData !== null && offerData.code !== null && offerData.code !== undefined ? offerData.code : ""}
          <img src={Copy} alt="" data-tooltip-target="tooltip-default" className="w-7 cursor-pointer" onClick={handleOffer}/>
        </div>
        <p className="text-center text-sm  font-primary-medium ">
          Offer Valid Till: {offerData !== undefined && offerData !== null && offerData.toDate !== null && offerData.toDate !== undefined ? offerData.toDate : ""}
        </p>
      </div>
    </>
  );
}

export default OfferCard