import { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import Header from './Header'
import MenuItem from './MenuItem'
import OrderContainer from './OrderContainer'
import CONFIG from '../../utils/Config';
import SearchElement from "./SearchBox";
import { useProduct } from '../../utils/context/ProductContext';
import { useCart } from '../../utils/context/CartContext';
import Dining from '../../assets/images/Dining.svg'
import emoji from '../../assets/images/neutral_face_color.svg'
import { useClient } from '../../utils/context/ClientContext';

const ProductList = (props) => {
  const { productData, setProducts, loadProductList } = useProduct();
  const clientContext = useClient();
  const cartContext = useCart();
  const [isSearchClicked, setIsSearchClicked] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [orderedProducts, setOrderedProducts] = useState([]);
  const [amount, setAmount] = useState(0);
  const loadingRef = useRef();
  const menuRef = useRef();
  const noDataRef = useRef();

  useEffect(() => {
    loadProductList(null, loadingRef, handleCartOrders);
  }, []);

  const handleCartOrders = (products) => {    
    let orderedProducts = [];    
    let cartItems = cartContext.cartItems;
    if (cartItems !== undefined && cartItems !== null && cartItems.length > 0) {
      cartItems.forEach((e) => {
        if (products) {
          let product = products.find((f) => f.item.id === e.product.id);
          if (product) {
            let productId = product.item.id;
            let productName = product.item.name;
            let qty = e.quantity;
            let rate = product.item.rate;
            let amount = rate * qty;
            let rating = product.item.feedback;
            let ratingCount = product.item.feedbackCount;
            let productData = {
              product: {
                id: productId,
                name: productName,
                category: product.item.category,
                subCategory: product.item.subCategory
              },
              quantity: qty,
              rate: rate,
              amount: amount,
              imageUrl: product.item.imageUrl,
              rating: rating,
              ratingCount: ratingCount
            };
            orderedProducts.push(productData);
          }
        }
      });      
      if (orderedProducts.length > 0) {
        let totalCost = 0;
        products.forEach((e) => {
          let cartItem = orderedProducts.find((f) => f.product.id === e.item.id);          
          if (cartItem && e.item.id === cartItem.product.id) {            
            e.item.quantity = cartItem.quantity;
            totalCost += e.item.quantity * e.item.rate;
          }
        });               
        setProducts(products);
        setOrderedProducts(orderedProducts);
        setAmount(totalCost);
      }
    }else{      
      setProducts(products);
    }
  }

  const handleOrderPreparation = (e) => {
    let products = orderedProducts;
    let valueUpdated = false;
    if (products.length > 0) {
      products.forEach((f) => {
        if (f.product.id === e.product.id) {
          f.quantity = e.quantity;
          f.amount = e.amount;
          valueUpdated = true;
        }
      });
    }
    if (!valueUpdated) {
      products.push(e);
    }
    products = products.filter((e) => e.quantity !== 0);
    let totalCost = 0;
    products.forEach((e) => {
      totalCost += e.amount;
    });
    setOrderedProducts(products);
    cartContext.setCart(products);
    setAmount(totalCost);
  };

  const getOrderData = () => {
    return orderedProducts;
  };

  const handleOrderPlacement = (e) => {
    const requestData = {
      billDetails: orderedProducts,
    };
    axios({
      url: CONFIG.URL + "public/order/placeOrder",
      method: "POST",
      headers: {
        "X-TenantID": clientContext.tenant
      },
      data: requestData,
    })
      .then((res) => {
        if (res.data.status === "Success") {
          alert("Order Placed Successfully at " + res.data.orderTime);
          window.location = "/orderSuccess";
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <div>
      <div className="md:w-[55%] h-fit items-center sticky top-[90px] bg-white z-20 w-full mx-auto py-3">
        <div className=" mx-auto bg-white">
          <div className="w-11/12 mx-auto flex bg-white">
            <SearchElement
              isHome={true}
              isSearchClicked={isSearchClicked}
            />
          </div>
        </div>
      </div>
      <div className="w-11/12 md:w-8/12 lg:w-6/12 mx-auto">
        <Header handleProductData={loadProductList} />
        <div>
          {/* Menu Items */}
          <div
            className=" w-full mx-auto mt-5 md:mt-6 mb-4"
            ref={menuRef}
          >
          </div>
          <div
            className="w-full relative mx-auto"
          >
            {!loaded && (<div ref={loadingRef} className="">
              {[...Array(parseInt(5))].map((e, index) => {
                return <MenuItem key={e} loading={true} />;
              })}
            </div>)
            }
            {productData !== null && productData !== undefined && productData.length > 0 ? (
                productData.map((data) => (
                <MenuItem
                  key={data.item.id}
                  handleOrderPreparation={handleOrderPreparation}
                  id={data.item.id}
                  name={data.item.name}
                  description={data.item.description}
                  imageUrl={data.item.imageUrl}
                  category={data.item.category}
                  subCategory={data.item.subCategory}
                  rate={data.item.rate}
                  quantity={data.item.quantity}
                  loading={false}
                  bestSeller={data.bestSeller}
                  rating={data.item.feedback}
                  ratingCount={data.item.feedbackCount}
                />
              ))
            ) : (
              <div
                ref={noDataRef}
              >
                <img src={Dining} alt="" className='w-80 h-80 mx-auto mb-4' />
                <div className='flex items-center text-lg md:text-xl font-primary-semibold text-gray-800 text-center gap-2 justify-center'>  
                <h1 className=''>Oh uh!</h1>                
                  <img src={emoji} className='w-6 h-6' alt="" />
                  <h1 className=''>We are closed now!</h1>
                </div>
                
              </div>
            )}
          </div>
        </div>
        <div className=" sticky -bottom-3 bg-white pb-1">
          {orderedProducts && orderedProducts.length > 0 ? (
            <OrderContainer
              qty={orderedProducts.length}
              amount={amount}
              getOrderData={getOrderData}
              handleOrderPlacement={handleOrderPlacement}
            />
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
}

export default ProductList;
