import React, { useState } from 'react';
import Search from '../../utils/icons/Search';

const SearchBox = ({ isHome, handleSearch }) => {

  const handleSearchLocally = (e) => {
    if (!isHome) {
      handleSearch(e.target.value);
    }
  };

  const handleRedirect = () => {
    if (isHome) {
      window.location = "/search";
    }
  };

  return (
      <div className="w-full relative">
        <input
            type="text"
            className="w-full font-primary-regular h-12 border-2 text-base rounded-lg relative pl-10 outline-none appearance-none "
            title="search box"
            placeholder="Search Here"
            onClick={handleRedirect}
            onChange={handleSearchLocally}
        />
        <Search className="w-10 h-10 absolute top-0  mt-1 text-center px-2 py-1" />
      </div>
  );
};

export default SearchBox;