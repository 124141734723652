import React, {useEffect, useState} from 'react'
import axios from 'axios';
import CONFIG from '../../utils/Config';
import {encryptData} from '../../utils/EncryptionUtil'
import MenuItemVertical from "./MenuItemVertical";
import RestaurantList from "./RestaurantList";


function FoodList({ searchString }) {
    const [foodItems, setFoodItems] = useState([]);
    const [id, setId] = useState();

    const [restaurantListVisibility, setRestaurantListVisibility] = useState(false);

    const handleVisibility = () => {
        setRestaurantListVisibility(!restaurantListVisibility); // Close the modal
    };
    useEffect(() => {
        axios({
            url: CONFIG.URL + "public/product/getAllProducts",
            method: 'GET',
            params:{
                'search': searchString
            },
            headers: {
                'X-TenantID': encryptData(CONFIG.DEFAULT_TENANT)
            }
        }).then((res) => {
            setFoodItems(res.data);
        }).catch((err) => {
            console.error(err);
        })
    }, []);
    return (
        <div className='w-11/12 px-2 md:w-9/12 mx-auto'>
            <div className=" overflow-y-auto py-2  grid grid-auto-fit-lg gap-2 md:gap-4">
                {foodItems !== null && foodItems.length > 0 && (
                    foodItems.map((product, index) => {
                        return <MenuItemVertical
                            product={product.item}
                            key={index}
                            isBestSeller={product.isBestSeller}
                            rating={product.rating}
                            type={1}
                            handleVisibility={handleVisibility}
                            setId={setId}
                        />
                    })
                )}
                {restaurantListVisibility && (
                    <RestaurantList
                        visibility={restaurantListVisibility}
                        handleVisibility={handleVisibility}
                        productId = {id}
                    />
                )}
            </div>
        </div>
    )
}

export default FoodList