import React, { Component, useEffect, useState } from 'react'
import icons from '../../utils/Icons';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import CONFIG from '../../utils/Config';
import TicketIcon from '../../utils/icons/TicketIcon';
import DownloadIcon from '../../utils/icons/DownloadIcon';
import { saveAs } from 'file-saver';
import RatingPopup from './RatingPopup';
import Stars from './ui/Stars';
import { PDFDownloadLink } from "@react-pdf/renderer";
import PdfDocument  from './PdfDocument';



export default function OrderItem(props) {
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState({
    selectedOption: 'chocolate',
  });
  const [selectOptions, setSelectOptions] = useState();
  const [orderStatus, setOrderStatus] = useState(props.orderStatus.name);
  const [colorCode, setColorCode] = useState(props.orderStatus.colorCode.split("$TF$")[0].toString());
  const [textColor, setTextColor] = useState("#eee");
  useEffect(() => {
    getNextStates(props.orderId);
  }, [])
  const redirectToStatusPage = () => {
    navigate("/status", {
      state: {
        orderId: props.orderId,
        orderTime: props.orderTime,
        orderDetails: props.orderDetails
      }
    });
  }
  const [ratingVisibility, setRatingVisibility] = useState(false);
  const handleVisibility = () => {
    setRatingVisibility(ratingVisibility == true ? false : true);
  }
  const handleChange = (selectedOption) => {
    let changeStateStatus = window.confirm("Are you sure you want to change the state? ");
    if (changeStateStatus) {
      axios({
        url: CONFIG.URL + "order/changeState",
        method: "POST",
        params: {
          'orderId': orderId,
          'stateId': selectedOption.value
        },
        headers: {
          'key': CONFIG.KEY,
          'token': CONFIG.TOKEN
        }
      }).then((res) => {
        alert("State Changes Successfully");
        setOrderStatus(res.data.state.name);
        setColorCode(res.data.state.colorCode.split("$TF$")[0].toString());
        let textColor = ""
        if (res.data.state.colorCode.split("$TF$")[1] === 1) {
          textColor = "#fff";
        } else {
          textColor = "#eee";
        }
        setTextColor(textColor);
        getNextStates(orderId);
      }).catch((err) => {
        console.error(err);
      });
    }
  };
  const getNextStates = (orderId) => {
    if (props.orderStatus.colorCode.split("$TF$")[1] == 1) {
      setTextColor("#fff");
    } else {
      setTextColor("#000");
    }

    axios({
      url: CONFIG.URL + "order/getNextStates",
      method: "GET",
      params: {
        'orderId': orderId
      },
      headers: {
        'key': CONFIG.KEY,
        'token': CONFIG.TOKEN
      }
    }).then((res) => {
      let options = [];
      res.data.map((e) => {
        let option = {
          label: e.name,
          value: e.id
        }
        options.push(option);
      });
      setSelectOptions(options);
    }).catch((err) => {
      console.error(err);
    });
  }
  const downloadReceipt = () => {
    let tokenId = sessionStorage.getItem("tokenId");    
    axios({
      url: CONFIG.URL + "client/downloadReceipt",      
      method: "GET",
      responseType: 'blob',
      headers: {
        'key': CONFIG.KEY,
        'token': CONFIG.TOKEN
      },
      params: {
        'tokenId': tokenId,
        'orderId': props.orderId
      }
    }).then((res) => {         
      let contentDisposition = res.headers['content-disposition'];      
      let fileName = contentDisposition.split('filename=')[1];      
      saveAs(res.data, fileName);  
    }).catch((err) => {
      alert("Download Failed!")      
      console.error(err);    
    })      
  }
  const { orderId, orderTime, token, amount } = props
  return (
    <div className="shadow-md border py-1 px-3 md:p-3 rounded-3xl w-full my-4">
      <div className="flex justify-between items-start gap-1 sm:gap-2 md:gap-3 w-full mx-auto rounded-2xl">
        <div className=" flex items-center  gap-1 sm:gap-2 md:gap-3">
          <div className="w-20 h-20 sm:w-20 sm:h-20 md:w-28 md:h-28 border bg-gradient-to-r from-indigo-200 via-red-200 to-yellow-100 rounded-xl md:rounded-2xl p-2.5  ">
            <TicketIcon className="w-16 h-16 md:w-24 md:h-24 text-primary" />
          </div>
          <div className="flex flex-col m-2 gap-0.5 md:gap-1.5 *:text-sm ">
            <h3
              className=" font-gotham-semibold md:text-lg text-primary cursor-pointer "
              onClick={redirectToStatusPage}
            >
              {orderId}
            </h3>

            <p className=" font-gotham-normal text-gray-700 md:text-sm">
              03/03/24, 12:55 pm
            </p>
            <p className="font-primary-medium md:text-sm ">
              Current status:
              <span
                className={
                  "py-0.5 px-1 md:py-1 md:px-2 text-xs rounded-md md:rounded-lg md:font-primary-medium ml-1 md:ml-2 "
                }
                style={{
                  color: textColor,
                  backgroundColor: colorCode,
                }}
              >
                {orderStatus}
              </span>
            </p>
            <h4 className="md:text-lg font-gotham-semibold">
              &#8377; {amount}
            </h4>
          </div>
        </div>
        <div className="flex flex-col justify-end items-center gap-1 text-xs xl:text-sm my-auto">
          <h4 className="md:text-lg font-primary-semibold">Get Receipt</h4>
          <PDFDownloadLink document={<PdfDocument orderData={props.orderData} clientData={props.clientData}/>} filename={orderId}>
            <button >
              <DownloadIcon className="w-10 h-10 text-primary-dark-blue" />
            </button>            
          </PDFDownloadLink>
        </div>
      </div>
      <hr className="my-2" />
      <div className="flex md:hidden justify-center items-center gap-1 text-xs xl:text-sm my-auto w-full">
        <div className="flex justify-center items-start gap-2 p-3 bg-amber-50 rounded-xl mt-2 w-full">
          <span>{icons.timer}</span>
          <p className="text-sm font-primary-regular text-blue-gray-800">
            Will be Delivered in: &nbsp;
            <span className="font-primary-bold">1hr 23min</span>
          </p>
        </div>
      </div>
      <div className="flex justify-center md:justify-between items-center">
        <div className="flex justify-center gap-2 my-4 items-center">
          <div className="font-primary-bold text-xs md:text-base whitespace-nowrap text-gray-800">
            Rate Your Order:
          </div>
          <div
            className="flex items-center cursor-pointer"
            onClick={() =>
              (props.rating === undefined || props.rating === 0) &&
              props.handleVisibility(orderId)
            }
          >
            <Stars
              id={orderId}
              filled={props.rating}
              notFilled={5 - props.rating}
              type={1}
            />
          </div>
        </div>
        <div className="hidden md:flex  md:flex-col md:justify-end items-center gap-1 text-xs xl:text-sm my-auto">
          <div className="flex justify-center items-start gap-2 p-3 bg-amber-50 rounded-xl mt-4">
            <span>{icons.timer}</span>
            <p className="text-sm font-primary-regular text-blue-gray-800">
              Will be Delivered in: &nbsp;
              <span className="font-primary-bold">1hr 23min</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
