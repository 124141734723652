import React, { useEffect, useRef, useState } from "react";
import Sheet from "react-modal-sheet";
import axios from "axios";
import CONFIG from "../../utils/Config";
import SecurityIcon from "../../utils/icons/SecurityIcon";
import Stars from "./ui/Stars";
import { useClient } from "../../utils/context/ClientContext";
import RatingItem from "./RatingItem";

const RatingPopup = ({ visibility, handleVisibility, orderId, toast }) => {
  const clientContext = useClient();
  const guestMobileRef = useRef(null);
  const [orderData, setOrderData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [productData, setProductData] = useState(Array[1]);
  const [ratingData, setRatingData] = useState();
  const [orderStarFilled, setOrderStarFilled] = useState(0);
  useEffect(() => {    
    if (orderData === null) {
      axios({
        url: CONFIG.URL + "public/order/get/" + orderId,
        method: 'GET',
        headers: {
          'X-TenantID': clientContext.tenant
        }
      }).then((res) => {
        setOrderData(res.data.orderData);
        let tempOrderData = res.data.orderData;
        let productsList = [];
        if (tempOrderData.bill !== null && tempOrderData.bill !== undefined) {
          tempOrderData.bill.billDetails.map((e) => {
            let product = {
              id: e.product.id,
              name: e.product.name,
              filled: 0,
              notFilled: 5,
              type: 1,
              product: e.product
            }
            productsList.push(product);
          })
        }
        setProductData(productsList);
        setIsModalOpen(visibility);
      }).catch((err) => {
        console.error(err);
      })
    }    
  }, []);

  const closeModal = () => {
    setIsModalOpen(false);
    handleVisibility();
  };
  const handleFill = (type, count, fillType, id) => {            
    if (id === orderId) {
      let filled = orderStarFilled;
      if (fillType == 2) {
        count = filled + count;
      } else {
        count = count;
      } 
      setOrderStarFilled(count);      
      let _ratingData = {
        ...ratingData,
        orderRating:{
          id:id,
          value:count
        }
      }
      setRatingData(_ratingData);      
    } else {
      let product = productData.filter((e) => { return e.id == id });
      let tempProducts = productData.filter((e) => { return e.id != id });
      let filled = product[0].filled;
      if (fillType == 2) {
        count = filled + count;
      } else {
        count = count;
      }      
      if (product[0] !== undefined && count !== parseInt(filled)) {
        product[0].filled = count;
        product[0].notFilled = (5 - count);
      }
      tempProducts.push(product[0]);
      tempProducts.sort((a, b) => { return a.id - b.id });
      setProductData(tempProducts);
      let productList = [];
      tempProducts.map((e) => {
        let productData = {
          id:e.id,
          value:e.filled
        }
        productList.push(productData);
      })
      let _ratingData = {
        ...ratingData,
        productRating:productList
      }
      
      setRatingData(_ratingData);
    }    
  }

  const rateOrder = () => {
    axios({
      url:CONFIG.URL+"public/order/rateOrder",
      method:'POST',
      data:ratingData,
      headers:{
        'key':CONFIG.KEY,
        'token':CONFIG.TOKEN
      }
    }).then((res) => {
      if(res.data === 'Success'){
        closeModal();  
        toast(1);
      }
    }).catch((err) => {
      console.error(err);
      toast(2);
    })
  }

  return (
    <>
      {isModalOpen && (
        <>
          <div>
            <Sheet
              isOpen={isModalOpen}
              onClose={() => closeModal()}
              detent="content-height"
              className="w-full md:w-6/12 mx-auto "
            >
              <Sheet.Container className="bg-sky-50 overflow-y-auto scrollbar-hide">
                <Sheet.Header/>
                <div className="text-center font-primary-medium sticky top-0 pt-4 bg-white w-full z-10">
                  Rate the experience on your recent order {" "}
                  <div className="font-gotham-semibold text-primary">#{orderId}</div>
                </div>
                <Sheet.Content style={{height: "800px"}}>
                  <div
                      className="mx-7 cursor-pointer flex flex-col gap-2"
                  >
                    {productData !== undefined &&
                        productData.length > 0 &&
                        productData.map((data) => (
                            <RatingItem product={data.product} data={data} handleFill={handleFill}/>
                        ))}
                  </div>
                  <hr className="mx-4 my-2 border"/>
                  <div className="mx-7 cursor-pointer flex flex-col gap-3">
                    <p className="font-primary-medium text-sm">
                      Overall experience on the platform
                    </p>
                    <Stars
                        id={orderId}
                        filled={orderStarFilled}
                        notFilled={5 - orderStarFilled}
                        handleFill={handleFill}
                        type={1}
                    />
                    <div>
                          <textarea name="comment" id="text_area"
                                    placeholder={"Do you want to write something?"}
                                    className={"w-full resize-none border border-gray-300 rounded-2xl p-4 font-primary-regular text-[15px]"}/>
                    </div>
                  </div>
                  <div className="flex w-11/12 md:w-4/12 mx-auto gap-5 justify-center">
                    <button
                        className="w-8/12 bg-gray-100 hover:bg-gray-200 border border-gray-300 p-2 rounded-md font-primary-semibold text-gray-900 my-5"
                        onClick={() => closeModal()}
                    >
                      Close
                    </button>
                    <button
                        className="w-8/12 bg-primary hover:bg-primary-hover text-gray-50 p-2 rounded-md font-primary-medium my-5"
                        onClick={rateOrder}
                    >
                      Submit
                    </button>
                  </div>
                  <div className="flex justify-center items-center gap-2 mx-auto w-10/12 py-3">
                    <SecurityIcon className="w-3 h-3"/>
                    <h1 className="text-xs font-primary-medium text-gray-500 text-center">
                      Your data is in a secured place now Enjoy your food.
                    </h1>
                  </div>
                </Sheet.Content>
              </Sheet.Container>
              <Sheet.Backdrop/>
            </Sheet>
          </div>
        </>
      )}
    </>
  );
};

export default RatingPopup;
