import CONFIG from "../../utils/Config";
import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import { motion } from "framer-motion";
import Navbar from "../elements/Navbar";
import Footer from "../elements/Footer";
import Cart from "../elements/Cart";
import { useNavigate } from "react-router-dom";
import UserDetailsPopUp from "../elements/UserDetailsPopUp";
import BillDetails from "../elements/BillDetails";
import Card from "../elements/MenuItemVertical";

import "perfect-scrollbar/css/perfect-scrollbar.css";
import OffersPopup from "../elements/OffersPopup";
import { useCart } from "../../utils/context/CartContext";
import RegistrationPopUp from "../elements/RegistrationPopUp";
import { useClient } from "../../utils/context/ClientContext";
import Loading from "../../assets/images/Loading.svg";
import ProductScanner from "../elements/ProductScanner";

const CartPage = () => {
  const cartContext = useCart();
  const clientContext = useClient();
  const navigate = useNavigate();  
  const [userData, setUserData] = useState(null);
  const [visibility, setVisibility] = useState(false);
  const [offersVisibility, setOffersVisibility] = useState(false);
  const [amount, setAmount] = useState();
  const [recommendedProducts, setRecommendedProducts] = useState([]);
  const [registrationPopupVisibility, setRegistrationPopupVisibility] = useState(false);
  const [comment, setComment] = useState(null);
  const loadingRef = useRef(null); 
  const [loading, setLoading] = useState(false); 

  useEffect(() => {
    window.scrollTo(0, 0);
    let amount = 0;
    let productIds = [];
    if (cartContext.cartItems != null && cartContext.cartItems != undefined && cartContext.cartItems.length > 0) {
      cartContext.cartItems.forEach((item) => {
        productIds.push(item.product.id);
        amount += item.amount;
      });
      setAmount(amount);
    }
    let data = {
      'productIds': productIds
    }
    axios({
      url: CONFIG.URL + "public/product/getRecommendedProducts",
      method: 'POST',
      data: data,
      headers: {
        "X-TenantID": clientContext.tenant
    }
    }).then((res) => {
      setRecommendedProducts(res.data);
    }).catch((err) => {
      console.error(err);
    })

  }, [cartContext.cartItems, cartContext.cartUpdated])
  const handleOrderPlacement = (userData) => {
    const requestData = {
      billDetails: cartContext.cartItems,
      tokenId: userData.tokenId,
      comment: comment
    };    
    axios({
      url: CONFIG.URL + "public/order/placeOrder",
      method: "POST",
      headers: {
        "X-TenantID": clientContext.tenant
      },
      data: requestData,
    })
      .then((res) => {
        
        if (res.data.status === "Success") {
          cartContext.resetCart();
          navigate("/orderSuccess", {
            state: {
              orderTime: res.data.orderTime,
              orderId: res.data.orderId,
              orderDetails: res.data.orderDetails
            },
          });
        }
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };
  const updateUserData = (data) => {
    setUserData(data);
    handleOrderPlacement(data);
  };
  const updateProductData = () => {
    let data = cartContext.cartItems;
    let amount = 0;
    if (data !== undefined && data !== null) {
      data.forEach((item) => {
        amount += item.amount;
      });
      setAmount(amount);
    }

  };
  const removeItem = (productId) => {
    let tempProductData = cartContext.cartItems.filter((e) => {
      return e.product.id !== productId;
    })
    cartContext.setCart(tempProductData);
  }
  const handleVisibility = (type) => {    
    const tokenId = sessionStorage.getItem("tokenId");
    const fullName = sessionStorage.getItem("fullName");
    const tableId = sessionStorage.getItem("tableId");
    if (tokenId != null && tokenId !== undefined) {
      let userData = {
        fullName: fullName,
        tableId: tableId,
        tokenId: tokenId
      }
      handleOrderPlacement(userData);
      return;
    }
    switch (type) {
      case 1:
        setVisibility(false);
        break;
      case 2:
        setVisibility(true);
        break;
      default:
        setVisibility(false);
        break;
    }
  };

  const handleRegistrationVisibility = () => {
    const tokenId = sessionStorage.getItem("tokenId");
    const fullName = sessionStorage.getItem("fullName");
    const tableId = sessionStorage.getItem("tableId");
    if (tokenId != null && tokenId !== undefined) {
      let userData = {
        fullName: fullName,
        tableId: tableId,
        tokenId: tokenId
      }
      handleOrderPlacement(userData);
      return;
    }
    setRegistrationPopupVisibility(registrationPopupVisibility === true ? false : true);
  };

  const handleOffersVisibility = () => {
    setOffersVisibility(offersVisibility === true ? false : true);
  }

  const handleComment = (comment) => {
    setComment(comment);
  }
  return (
    <div className=" grid grid-cols-1 content-between h-screen">
      <div className="sticky top-0 z-50">
        <Navbar />
      </div>
      {loading && (
        <div ref={loadingRef} className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <img src={Loading} alt="" />
        </div>
      )}
      <motion.div animate={{ y: -25 }} transition={{ duration: 0.5 }}>
        <div className="self-start mx-auto block md:flex justify-between align-top gap-5 min-h-screen   w-full lg:w-10/12 py-3 rounded md:rounded-lg mb-3 mt-6">
          <ProductScanner/>
          <Cart
            handleClick={handleVisibility}            
            productData={cartContext.cartItems}
            userData={userData}
            handleOrderPlacement={handleOrderPlacement}
            removeItem={removeItem}
            updateProductData={updateProductData}
          />
          {cartContext.cartItems !== undefined && cartContext.cartItems !== null && cartContext.cartItems.length > 0 && (<div className="container w-11/12 md:w-8/12 mx-auto block md:hidden p-4">
            <span className="inline-block text-xl font-primary-bold pb-4">
              You may also like
            </span>
            <div className="flex gap-6 md:flex-nowrap no-scrollbar overflow-x-auto relative pb-6"
              style={{ width: "100%" }}
            >
              {recommendedProducts && recommendedProducts.map((product, index) => (
                <Card
                  product={product.item}
                  key={index}
                  isBestSeller={product.isBestSeller}   
                  rating={product.rating}                                
                />
              ))}
            </div>
          </div>)
          }

          {cartContext.cartItems !== null && cartContext.cartItems !== undefined && cartContext.cartItems.length > 0 ? 
          <BillDetails amount={amount} handleClick={handleVisibility} 
            handleLoginVisibility={handleRegistrationVisibility} 
            handleOffersVisibility={handleOffersVisibility} 
            handleComment={handleComment} 
            comment={comment} /> : <></>}
        </div>
        {
          cartContext.cartItems !== null && cartContext.cartItems !== undefined && cartContext.cartItems.length > 0 && (<div className="container w-11/12 mx-auto hidden md:block">
            <span className="inline-block text-xl font-primary-bold py-4">
              You may also like
            </span>
            <div className="flex gap-3 overflow-x-auto relative"
            >
              {recommendedProducts && recommendedProducts.map((product, index) => (
                <Card
                  data={product}
                  product={product.item}
                  key={index}
                  count="450"
                  rating="4.5"
                />
              ))}
            </div>
          </div>)
        }

      </motion.div>

      <UserDetailsPopUp
        handleVisibility={handleVisibility}
        visibility={visibility}
        updateUserData={updateUserData}
        handleOrderPlacement={handleOrderPlacement}
      />
      {offersVisibility === true ? (<OffersPopup visibility={offersVisibility} handleVisibility={handleOffersVisibility} amount={amount} />) : (<></>)}
      <Footer />
      <>
        <RegistrationPopUp visibility={registrationPopupVisibility} handleVisibility={handleRegistrationVisibility} handleOrderPlacement={handleOrderPlacement}  updateUserData={updateUserData} setLoading={setLoading}/>
      </>
      
    </div>
  );
};

export default CartPage;
