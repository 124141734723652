import "./index.css";
import HomePage from './components/pages/HomePage';
import SearchPage from './components/pages/SearchPage';
import ErrorPage from './components/pages/ErrorPage';
import StatusPage from './components/pages/StatusPage';
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Navigate,
} from "react-router-dom";
import {AnimatePresence} from 'framer-motion';
import OrderSuccessPage from './components/pages/OrderSuccessPage';
import CartPage from './components/pages/CartPage';
import OrdersPage from './components/pages/OrdersPage';
import ContactUsPage from './components/pages/ContactUsPage';
import {useEffect, useRef, useState} from "react";
import FeedbackPopup from "./components/elements/FeedbackPopup";
import {ProductContextProvider} from "./utils/context/ProductContext";
import {FiltersContextProvider} from "./utils/context/FiltersContext";
import {CartContextProvider} from "./utils/context/CartContext";
import ClientsPage from "./components/pages/ClientsPage";
import {ThemeProvider} from '@mui/material';
import {createTheme} from '@mui/material/styles';
import {ThemeProvider as Theme} from '@material-tailwind/react'
import RequireClient from "./utils/context/RequireClient";
import {LocationContextProvider} from "./utils/context/LocationContext";


/**
 * The main application component that sets up the routing and context providers for the app.
 * It also handles geolocation to set the client's district and state if not already set.
 *
 * @returns {JSX.Element} The main application component with routing and context providers.
 */
function App() {
    const [visibility, setVisibility] = useState(false);
    const [eventsource, setEventSource] = useState(null);

    // Define your theme with the custom font
    const theme = createTheme({
        typography: {
            h1: 'primary-bold',
            h2: 'primary-bold',
            h3: 'primary-bold',
            h4: 'primary-bold',
            h5: 'primary-bold',
            body1: 'primary-regular',
            body2: 'primary-regular',
            subtitle1: 'primary-regular',
            subtitle2: 'primary-regular',
            caption: 'primary-regular',
            button: 'primary-regular',
            overline: 'primary-regular'
        },
        shape: {
            borderRadius: '10px'
        },
        components: {
            MuiInputBase: {
                styleOverrides: {
                    input: {
                        borderRadius: '20px'
                    }
                }
            },
            MuiOutlinedInput: {
                styleOverrides: {
                    input: {
                        borderRadius: '10px'
                    }
                }
            },
            MuiInput: {
                styleOverrides: {
                    input: {
                        borderRadius: '10px'
                    }
                }
            },
            MuiTextField: {
                styleOverrides: {
                    input: {
                        borderRadius: '10px'
                    }
                }
            },
            MuiInputAdornment: {
                styleOverrides: {
                    input: {
                        borderRadius: '10px'
                    }
                }
            },
            MuiFilledInput: {
                styleOverrides: {
                    input: {
                        borderRadius: '10px'
                    }
                }
            },
        }
    });
    /**
     * Handles the visibility state of the feedback popup.
     */
    const handleVisibility = () => {
        setVisibility(false);
    }

    useEffect(() => {
        return () => {
            if (eventsource) {
                eventsource.close();
                setEventSource(null);
            }
        };
    }, [visibility]);

    /**
     * Stops the event source if it is active.
     */

    const stopEvent = () => {
        if (eventsource) {
            eventsource.close();
            setEventSource(null);
        }
    }

    return (
        <div>
            <Theme>
                <ThemeProvider theme={theme}>
                    <FeedbackPopup visibility={false} handleVisibility={handleVisibility} stopEvent={stopEvent}/>
                    <LocationContextProvider>
                        <FiltersContextProvider>
                            <ProductContextProvider>
                                <CartContextProvider>
                                    <AnimatePresence>
                                        <Router>
                                            <Routes>
                                                <Route path="/home"
                                                       element={<RequireClient><HomePage/></RequireClient>}>
                                                </Route>
                                                <Route path="/orders"
                                                       element={<RequireClient><OrdersPage/></RequireClient>}>
                                                </Route>
                                                <Route path="/status"
                                                       element={<RequireClient><StatusPage/></RequireClient>}>
                                                </Route>
                                                <Route path="/search"
                                                       element={<RequireClient><SearchPage/></RequireClient>}>
                                                </Route>
                                                <Route path="/error" element={<ErrorPage/>}>
                                                </Route>
                                                <Route path="/orderSuccess"
                                                       element={<RequireClient><OrderSuccessPage/></RequireClient>}>
                                                </Route>
                                                <Route path="/viewCart"
                                                       element={<RequireClient><CartPage/></RequireClient>}>
                                                </Route>
                                                <Route path="/contactUs"
                                                       element={<RequireClient><ContactUsPage/></RequireClient>}>
                                                </Route>
                                                <Route path="/clients" element={<ClientsPage/>}>
                                                </Route>
                                                <Route path="*" element={<Navigate to="/clients"/>}/>
                                            </Routes>
                                        </Router>
                                    </AnimatePresence>
                                </CartContextProvider>
                            </ProductContextProvider>
                        </FiltersContextProvider>
                    </LocationContextProvider>
                </ThemeProvider>
            </Theme>
        </div>
    );
}


export default App;
