import {enc, AES} from 'crypto-js';

const key = enc.Base64.parse(process.env.REACT_APP_CF_APP_KEY);
const iv = enc.Base64.parse(process.env.REACT_APP_CF_APP_IV);

export const encryptData = (data) => {
    return AES.encrypt(data, key, { iv: iv });
}

export const decryptData = (data) => {
    return AES.decrypt(data, key, { iv: iv }).toString(enc.Utf8);
}
