import React from 'react'
import contactUs from "../../assets/images/ContactUs.svg"
import icons from '../../utils/Icons'

export default function ContactForm() {
    return (
      <div className=" mx-auto mb-10 ">
        <div className="flex justify-center h-80 md:h-[500px] bg-yellow-200">
          <img src={contactUs} alt="discuss" className="w-72 h-72" />
        </div>
        <div className="px-1 md:px-4 w-11/12 md:w-7/12 xl:w-5/12 mx-auto">
          <div className=" shadow-lg bg-white rounded-xl lg:rounded-2xl -mt-16 md:-mt-60 py-5 px-4 md:py-8 md:px-8">
            <div className="flex justify-center items-center w-full mx-auto py-2 md:py-5">
              <div>
                <h1 className="text-xl md:text-2xl text-center font-primary-bold mb-5">
                  Contact Us
                </h1>
                <div className=" *:my-2 *:text-base ">
                  <label className="font-primary-semibold" htmlFor="email">Email</label>
                  <input
                    type="text"
                    id="email"
                    className="w-full border border-primary-gray rounded md:rounded-lg p-2 focus:outline-none focus:border-sky-500 focus:shadow-none"
                    placeholder="Email"
                  />
                </div>
                <div className="*:my-2 *:text-base ">
                  <label className="font-primary-semibold" htmlFor="message">Message</label>
                  <textarea
                    cols="30"
                    rows="10"
                    id="message"
                    className="border border-primary-gray rounded md:rounded-lg w-full p-2 mt-2 h-40 resize-none focus:outline-none focus:border-sky-500 focus:shadow-none"
                    placeholder="Your message here..."
                  ></textarea>
                </div>
                <button className="flex gap-1 justify-center items-center px-4 py-2 mx-auto my-3 font-primary-bold text-white bg-green-600 hover:bg-green-500 rounded-lg shadow-lg cursor-pointer">
                  <span>{icons.send}</span> <span>Submit</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}
