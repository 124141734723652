import React, {useState} from "react";
import { Box, Tab, Tabs } from "@mui/material";
import PropTypes from "prop-types";
import ClientsList from "./ClientsList";
import SearchBox from "./SearchBox";
import FoodList from "./FoodList";


function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box>{children}</Box>}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function TabWrapper() {
    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const [ searchString, setSearchString ] = useState('');

    const handleSearch = (searchString) => {
        setSearchString(searchString);
    }
    const data = [
        {
            label: "Find by Restaurants",
            value: 0,
            desc: <ClientsList />,
        },
        {
            label: "Find by Food",
            value: 1,
            desc: <FoodList searchString = { searchString }/>,
        }
    ];
    return (
        <div >
            <div className=" h-fit items-center sticky top-[80px] bg-green z-20 w-full mx-auto py-3 bg-white">
                <Tabs value={value} centered={true} className={'mt-3 font-primary-medium'} onChange={handleChange}>
                    {data.map(({ label, value }) => (
                        <Tab key={value} value={value} label={label} />
                    ))}
                </Tabs>
                <div className="w-11/12 md:w-9/12 mx-auto h-fit items-center bg-white z-20 py-3">
                    <SearchBox handleSearch={handleSearch}/>
                </div>
            </div>

            {data.map(({ label, desc }, index) => (
                <CustomTabPanel key={index} value={value} index={index}>
                    {desc}
                </CustomTabPanel>
            ))}
        </div>

    );
}

export default TabWrapper;