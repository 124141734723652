import React, { useEffect, useState } from 'react'
import { useExtractColor } from "react-extract-colors";
import Skeleton from 'react-loading-skeleton';
import "react-loading-skeleton/dist/skeleton.css";
import VegIcon from '../../utils/icons/VegIcon';
import NonVegIcon from '../../utils/icons/NonVegIcon';
import Star from '../../utils/icons/Star';

const MenuItem = props => {
  const [state, setState] = useState({    
    qty: props.quantity !== undefined && props.quantity !== null ? props.quantity : 0
  });
  const[loading, setLoading] = useState(props.loading);
  const { dominantColor, darkerColor, lighterColor } = useExtractColor(props.imageUrl);
  const customGradientStyle = {
    backgroundImage: 'linear-gradient(to top,' + dominantColor + ', transparent)',
    width: '100%',
    height: '50%',
    position: 'absolute',
    bottom: 0,
    left: 0,
  };
  const tempOrderPreparation = (count) => {
    let productId = props.id;
    let productName = props.name;
    let qty = count;
    let rate = props.rate;
    let amount = rate * qty;
    let productData = {
      product: {
        id: productId,
        name: productName,
        category: props.category,
        subCategory: props.subCategory
      },
      quantity: qty,
      rate: rate,
      amount: amount,
      imageUrl: props.imageUrl
    }
    props.handleOrderPreparation(productData);
  }

  const handleQty = (e) => {    
    let count = state.qty;
    let classNameValue = state.className;
    let favouriteData = state.favourite;
    let type = e.target.getAttribute("id");
    if (type == 'increase') {
      count += 1;
      setState({
        qty: count,
        className: classNameValue,
        favourite: favouriteData
      })
    } else {
      if (count > 0) {
        count -= 1;
        setState({
          qty: count,
          className: classNameValue,
          favourite: favouriteData
        })
      }
    }
    tempOrderPreparation(count);
  }

  return (
    <div className="flex gap-1 w-full mx-auto my-3 p-3 md:p-4 shadow-md rounded-2xl border">
      <div className="flex flex-col w-full ml-1">
        <div>
          <h3 className="flex gap-1 items-center text-primary-blue text-xs font-primary-medium mb-1">
            {loading && (
              <Skeleton width="100%" containerClassName="avatar-skeleton" />
            )}
            {!loading && props.bestSeller === true && (
              <div className="px-3 py-0.5 rounded-full bg-[conic-gradient(at_top_left,_var(--tw-gradient-stops))] from-yellow-700 via-amber-300 to-yellow-700 text-white font-primary-bold">
                Bestseller
              </div>
            )}
          </h3>
          <h3 className="text-base md:text-lg font-primary-semibold text-heading mb-1">
            {loading && (
              <Skeleton width="100%" containerClassName="avatar-skeleton" />
            )}
            {!loading && (
              <div className="">
                <div>{props.name}</div>
              </div>
            )}
          </h3>
          <div className="font-primary-regular">
            {!loading && (
              <div className="flex gap-1 items-center">
                <span className="">
                  {props.category.name === "Veg" ? (
                    <VegIcon className="w-5 h-5 text-green-600 fill-current" />
                  ) : (
                    <NonVegIcon className="w-5 h-5 text-red-600 fill-current" />
                  )}
                </span>
                <small className="text-gray-800 font-primary-bold text-xs ">
                  {props.subCategory.label}
                </small>
              </div>
            )}
          </div>
          <div className="font-primary-regular">
            {loading && (
              <Skeleton width="20%" containerClassName="avatar-skeleton" />
            )}
            {!loading && props.rating > 0 && (
              <div className="flex justify-start items-center gap-1 my-1 ">
                <Star className="text-yellow-400 w-4 h-4" />
                <span className="font-primary-semibold text-xs">{props.rating}</span>
                <span className="text-gray-600 text-xs">({props.ratingCount}) Ratings</span>
              </div>
            )}
          </div>
          <div className="">
            <h4 className="text-lg font-gotham-semibold ">
              {loading && (
                <Skeleton width="100%" containerClassName="avatar-skeleton" />
              )}
              {!loading && <div> &#8377; {props.rate}</div>}
            </h4>
          </div>
          <p className="text-xs md:text-sm text-gray-500 font-primary-regular line-clamp-2 mb-1 ">
            {loading && (
              <Skeleton
                width="100%"
                height={30}
                containerClassName="avatar-skeleton"
              />
            )}
            {!loading && <div>{props.description}</div>}
          </p>
        </div>
      </div>
      <div>
        <div className="">
          <div className="w-32 h-32 md:w-32 md:h-32 rounded-lg relative overflow-hidden">
            {loading && (
              <Skeleton height="100%" containerClassName="avatar-skeleton" />
            )}
            {!loading && (
              <>
                <img
                  src={props.imageUrl}
                  alt=""
                  className="w-full h-full object-cover"
                />
                <div style={customGradientStyle}></div>
                <div className="flex pr-1 md:pr-0">
                  {loading && (
                    <Skeleton
                      width="100%"
                      height="100%"
                      containerClassName="avatar-skeleton"
                    />
                  )}
                </div>
              </>
            )}
            {loading && (
              <Skeleton height="100%" containerClassName="avatar-skeleton" />
            )}
          </div>
        </div>
        <div className="mt-2">
          {!loading && (
            <div className="w-24  px-2  bg-blue-50 border-2 border-primary flex gap-2 justify-between items-center  rounded-lg text-base font-primary-medium mx-auto">
              <button
                id="reduce"
                onClick={handleQty}
                className="font-primary-semibold text-2xl pb-1"
              >
                -
              </button>
              <p className="w-7 md:w-10 text-center md:px-2 text-base md:text-xl font-gotham-medium">
                {state.qty}
              </p>
              <button
                id="increase"
                onClick={handleQty}
                className="font-primary-semibold text-2xl pb-1"
              >
                +
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}


export default MenuItem;
