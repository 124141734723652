import React, { useEffect} from 'react'
import Navbar from '../elements/Navbar'
import ProductList from '../elements/ProductList'
import Footer from '../elements/Footer'
import { motion } from 'framer-motion'
import { useLocation } from 'react-router-dom'
import { AdvancedFilters } from '../elements/AdvancedFilters'
import { FiltersContextProvider} from '../../utils/context/FiltersContext'
import { useClient } from '../../utils/context/ClientContext'



function HomePage() {
  const location = useLocation();
  const clientContext = useClient();
  useEffect(() => {
    window.scrollTo(0, 0);
    if (location.search != null) {
      let tableId = location.search.replace("?", "").split("tableId=")[1];
      sessionStorage.setItem("tableId", tableId)
      let tenantId = location.search.replace("?", "").split("tenantId=")[1];
      if(tenantId !== undefined && tenantId !== null){
        clientContext.setTenant(tenantId);
      }
    }
  }, [])
  return (
    <FiltersContextProvider>
      <div>
        <div className="sticky top-0 z-10">
          <Navbar />
        </div>
        <div className="z-20">
          <AdvancedFilters />
        </div>
        <motion.div animate={{ y: -10 }} transition={{ duration: 0.5 }}>
          <div className="w-11/12 md:w-8/12 lg:w-6/12 my-3 mx-auto pt-4 pb-0 md:py-5 text-center">
            <h1 className=" text-wrap text-xl md:text-2xl pb-1 font-primary-bold text-heading">
              Welcome to {clientContext.name}!
            </h1>
            <h1 className=" text-sm md:text-base text-subheading font-primary-regular">
              We are here to serve you! what do you want to have?
            </h1>
          </div>
          <div className="mb-20">
            <ProductList
              isProductList={true}
              cartProducts={
                location.state && location.state.productData
                  ? location.state.productData
                  : []
              }
            />
          </div>
        </motion.div>
        
        <Footer />
      </div>
    </FiltersContextProvider>
  );
}
export default HomePage
