import { React, createContext, useContext, useEffect, useState } from 'react'
import CONFIG from '../Config';
import { useFilters } from './FiltersContext';
import axios from 'axios';
import { useClient } from './ClientContext';

const ProductContext = createContext(null);

export const ProductContextProvider = ({ children }) => {
    const filters = useFilters();
    const clientContext = useClient();
    const [productData, setProductData] = useState(() => {
        const savedProductsData = sessionStorage.getItem('productData');
        return savedProductsData !== 'undefined' ? JSON.parse(savedProductsData) : null;
    });

    useEffect(() => {
        sessionStorage.setItem('productData', JSON.stringify(productData));
    }, [productData]);

    const loadProductList = (filterData, loadingRef, handleCartOrders) => {        
        let data;
        if (filters !== null && filters.filtersData === undefined || filters.filtersData === null) {
            let filter = {};
            data = {
                filter: filter
            }
        } else {
            data = filters.filtersData;
        }
        if (filterData !== null && filterData !== undefined) {
            data = filterData;
        }
        filters.setFilters(data);
        axios({
            url: CONFIG.URL + "public/product/getProducts",
            method: "POST",
            data: data,
            headers: {
                "X-TenantID": clientContext.tenant
            }
        }).then((res) => {      
            if (loadingRef !== null && loadingRef !== undefined) {
              loadingRef.current.classList = "hidden";
            }                  
            let productsResponse = res.data;                    
            if(handleCartOrders !== undefined){
                handleCartOrders(productsResponse);    
            }else{
                setProductData(productsResponse);
            }
        }).catch((err) => {     
            if (
              loadingRef !== null &&
              loadingRef !== undefined &&
              loadingRef.current !== null &&
              loadingRef.current !== undefined
            ) {
              loadingRef.current.classList = "hidden";
            }
        });
    };
    const setProducts = (products) => {
        setProductData(products)
    }
    const resetProducts = () => {
        setProductData(null);
    }
    return <ProductContext.Provider value={{ productData, loadProductList, resetProducts, setProducts }}>{children}</ProductContext.Provider>
}

export const useProduct = () => {
    return useContext(ProductContext)
}