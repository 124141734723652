import React, { useEffect, useState } from "react";
import icons from "../../utils/Icons";
import Offer from "../../utils/icons/Offer";
import { useCart } from "../../utils/context/CartContext";
import axios from "axios";
import CONFIG from "../../utils/Config";
import { useClient } from "../../utils/context/ClientContext";


const BillDetails = (props) => {
  const clientContext = useClient();
  const [amount, setAmount] = useState(props.amount);
  const [totalAmount, setTotalAmount] = useState();
  const [gst, setGST] = useState();
  const [conFee, setConFee] = useState();
  const [discount, setDiscount] = useState(null);
  const cartContext = useCart();
  const [comment, setComment] = useState(null);
  const [minimumOffer, setMinimumOffer] = useState(null);
  useEffect(() => {
    if (minimumOffer === null) {
      axios({
        url: CONFIG.URL + "public/offers/getMinimumOffer",
        method: 'GET',
        headers: {
          'X-TenantID': clientContext.tenant
        }
      }).then((res) => {
        setMinimumOffer(res.data);
      })
    }
    let GST = props.amount * 0.05;
    let convienienceFee = props.amount * 0.05;
    let discountAmount = 0;
    if (cartContext.offerData !== null && cartContext.offerData !== undefined) {
      console.log(cartContext.offerData);
      let discountPercent = cartContext.offerData.discount;
      discountAmount = props.amount * (discountPercent / 100);
      setDiscount(discountAmount);
    } else {
      setDiscount(null);
    }
    setGST(GST);
    setConFee(convienienceFee);
    setTotalAmount(props.amount + GST + convienienceFee - discountAmount);
  }, [props.amount, cartContext.offerData])

  const handleComment = (e) => {
    setComment(e.target.value);
    props.handleComment(e.target.value)
  }
  return (
    <div className="w-full md:w-4/12 mb-3 rounded-2xl shadow-lg mt-5 bg-white border p-5 md:p-10 max-h-[600px] ">
      <h3 className="font-primary-bold text-lg text-gray-900  mb-3">Details</h3>
      <div className="text-gray-900 ">
        {/* <div className="flex justify-start gap-2 *:font-primary-bold *:text-lg text-gray-800">
          <span>3 </span>
          <h3>Items</h3>
        </div> */}
        <div className="grid grid-flow-col py-1 md:py-2">
          <p className="font-primary-medium text-sm md:text-base">Amount</p>
          <p className="font-gotham-medium text-sm md:text-base text-right">
            ₹ {props.amount}
          </p>
        </div>
        <div className="grid grid-flow-col py-1 md:py-2">
          <p className="font-primary-medium text-sm md:text-base">
            Convenience Fee
          </p>
          <p className="font-gotham-medium text-right text-sm md:text-base ">
            ₹ {conFee}
          </p>
        </div>
        <div className="grid grid-flow-col py-1 md:py-2">
          <p className="font-primary-medium text-sm md:text-base">GST at 5%</p>
          <p className="font-gotham-medium text-right text-sm md:text-base ">
            ₹ {gst}
          </p>
        </div>
        {
          discount !== null ?
            (
              <div className="grid grid-flow-col py-1 md:py-2">
                <p className="font-primary-medium text-sm md:text-base">Discount</p>
                <p className="font-gotham-medium text-right text-sm md:text-base ">
                  - ₹ {discount}
                </p>
              </div>
            ) : (<></>)
        }

        <div className="grid grid-flow-col py-4 border-t mt-3 *:font-primary-bold border-t-slate-700">
          <p>Total</p>
          <p className="font-gotham-bold text-right text-base md:text-base ">
            ₹ {totalAmount}
          </p>
        </div>
      </div>
      <input
        type="text"
        name=""
        id=""
        value={comment}
        onChange={handleComment}
        className="px-2 py-3 w-full text-base border-2 border-gray-200 rounded-lg placeholder:text-gray-300 my-1 md:my-3 focus:border-gray-600 focus:outline-none focus:shadow-none"
        placeholder="Do you want say anything?"
      />
      <div className="flex justify-start items-center gap-2 w-full my-4">
        <Offer width="28" height="28" className="fill-sky-500" />
        {cartContext.offerData !== null ? (
          <p className="font-primary-regular text-sm ">
            Applied <span className="font-primary-bold text-lg">{cartContext.offerData.name} </span>
            <a className="text-sky-600 underline underline-offset-1 " onClick={() => {
              cartContext.resetOffer()
            }}>Click here</a> to remove.
          </p>
        ) : (
          <p className="font-primary-regular text-sm cursor-pointer">
            {(minimumOffer !== null) && (minimumOffer.minimumAmount > totalAmount) && (<>Add More products worth <span className="font-gotham-semibold">₹{(minimumOffer.minimumAmount - totalAmount)}</span> to avail <span className="font-primary-bold">{minimumOffer.discount}%</span> Offer!</>)}
            {
              (minimumOffer !== null) && (minimumOffer.minimumAmount <= totalAmount) && (
                <>
                  Offers available{" "}
                  <a className="text-sky-600 underline underline-offset-1 " onClick={() => {
                    props.handleOffersVisibility();
                  }}>Click here</a> for more details.</>)
            }
          </p>
        )
        }
      </div>
      <button
        className="w-full mt-4 p-3 bg-primary rounded-lg text-center font-primary-medium text-white "
        onClick={() => {
          props.handleLoginVisibility();
        }}
      >
        Proceed to Pay ₹ {totalAmount}
      </button>
      <div className="flex justify-center items-center gap-2 p-3 bg-green-50 rounded-xl mt-4">
        <span>{icons.timer}</span>
        <p className="text-sm md:text-base font-primary-medium text-blue-gray-800">
          Estimated delivery time: &nbsp;
          <span className="font-primary-bold">1hr 23min</span>
        </p>
      </div>

    </div>
  );
};

export default BillDetails;
