import React, { useEffect, useState } from "react";
import CloseIcon from "../../utils/icons/CloseIcon";
import axios from "axios";
import CONFIG from "../../utils/Config";
import {encryptData} from "../../utils/EncryptionUtil";
import Card from "./Card";
import Sheet from "react-modal-sheet";


const RestaurantList = ({ visibility, handleVisibility, productId }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const [clients, setClients] = useState([]);
    useEffect(() => {
        axios({
            url: CONFIG.URL + "public/client/getClients",
            method: 'GET',
            withCredentials: true,
            params:{
                'state':'Tamil Nadu',
                'district':'Thanjavur',
                'productId': productId
            },
            headers: {
                'X-TenantID': encryptData(CONFIG.DEFAULT_TENANT)
            }
        }).then((res) => {
            console.log(JSON.stringify(res.data));
            setClients(res.data);
        }).catch((err) => {
            alert('Error: ' + err.message);
            console.error(err);
        })
    }, [productId]);

    useEffect(() => {
        setIsModalOpen(visibility);
    }, [visibility]); // Dependency to rerun effect only when `visibility` changes

    const closeModal = () => {
        setIsModalOpen(false);
        handleVisibility(); // Close the modal by updating parent state
    };

    return (
        <>
            <Sheet
                isOpen={isModalOpen}
                onClose={closeModal}
                detent="content-height"
                className="w-full md:w-8/12 mx-auto"
            >
                <Sheet.Container>
                    <Sheet.Header />
                    <Sheet.Content>
                        <div className="hidden md:block px-2 md:px-10 cursor-pointer" onClick={closeModal}>
                            <CloseIcon
                                className="w-10 h-10 ml-auto font-primary-semibold text-gray-400 fill-current rounded-lg p-1"/>
                        </div>
                        <h1 className="font-primary-bold text-xl text-center">
                            Available Restaurants
                        </h1>
                        <div
                            className="w-full md:w-11/12 mx-auto p-2 flex flex-col gap-4 my-8 max-h-[80vh] overflow-y-auto scrollbar-hide">
                            {clients && clients.length > 0 ? (
                                clients.map((client, index) => (
                                    <Card key={index} client={client}/>
                                ))
                            ) : (
                                <p className="text-center text-gray-500">No clients found.</p>
                            )}
                        </div>
                    </Sheet.Content>
                </Sheet.Container>
                <Sheet.Backdrop/>
            </Sheet>
        </>
    );
};

export default RestaurantList;
