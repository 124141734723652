import React, { useEffect, useState } from "react";
import Sheet from "react-modal-sheet";
import UserIcon from "../../utils/icons/UserIcon";
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import axios from "axios";
import CONFIG from "../../utils/Config";
import SecurityIcon from "../../utils/icons/SecurityIcon";
import toast, { Toaster } from "react-hot-toast";
import ReCAPTCHA from "react-google-recaptcha";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'
import { useClient } from "../../utils/context/ClientContext";
import CloseIcon from "../../utils/icons/CloseIcon";
import { TextField } from "@mui/material";
import Google from "../../assets/images/Google.svg"
import {encryptData} from "../../utils/EncryptionUtil";
const RegistrationPopUp = ({ visibility, handleVisibility, handleOrderPlacement, updateUserData, setLoading }) => {
  const { tenant } = useClient();
  const [name, setName] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [otpGenerated, setOtpGenerated] = useState(false);
  const [captchaValidated, setCaptchaValidated] = useState(null);
  const [mobileNumber, setMobileNumber] = useState(null);
  const [ guestMode, setGuestMode ] = useState(false);
  const [errors, setErrors] = useState({
    captcha: false,
    mobile: false
  });

  useEffect(() => {
    setIsModalOpen(visibility);
  });

  const registerUser = (e) => {
    setLoading(true);
    const tokenId = sessionStorage.getItem("tokenId");
    const fullName = sessionStorage.getItem("fullName");
    let tableId = sessionStorage.getItem("tableId");
    if (tableId ==="undefined" || tableId === undefined) {
      tableId = 1;
    }
    let userData = {
      fullName: name,
      tableId: tableId,
    };
    debugger;
    if (
      tokenId === undefined ||
      tokenId == null ||
      tokenId.toString().length === 0
    ) {
      axios({
        url: CONFIG.URL + "user/public/register",
        data: userData,
        method: "POST",
        headers: {
          "X-TenantID": encryptData(CONFIG.DEFAULT_TENANT)
        },
      })
        .then((res) => {
          userData = res.data;
          sessionStorage.setItem("tokenId", userData.tokenId);
          sessionStorage.setItem("fullName", userData.fullName);
          updateUserData(userData);
        })
        .catch((err) => {
          console.error(err);
        });
      handleVisibility();
    } else {
      userData = {
        fullName: fullName,
        tableId: tableId,
        tokenId: tokenId,
      };
      handleOrderPlacement(userData);
    }
    handleVisibility();
  };

  const closeModal = () => {
    setIsModalOpen(false);
    handleVisibility();
  };
  const handleInput = (e) => {
    setName(e.target.value);
  };
  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <></>,
    prevArrow: <></>,
    swipeToSlide: false,
    swipe: false
  };

  const handleNext = () => {
    registerUser();
  }

  const handleGeneratingOTP = (e) => {
    if (captchaValidated != null) {
      toast.success('OTP sent successfully to your mail');
      setOtpGenerated(true);
      setErrors({
        ...errors,
        captcha: false
      })
      let data = {
        mobileNumber: mobileNumber
      }
      axios({
        url: CONFIG.URL + "login/generateOTP",
        data: data,
        method: 'POST'
      })
    } else {
      setErrors({
        ...errors,
        captcha: true
      })
    }
  }
  return (
    <>
      {isModalOpen && (
        <>
          <div>
            <Sheet
              isOpen={isModalOpen}
              onClose={() => closeModal()}
              detent="full-height"
              className="w-full md:w-8/12 mx-auto "
            >
              <Sheet.Container>
                <Sheet.Header />
                <Sheet.Content>
                  <Toaster />
                  <Slider {...settings}>
                    <>
                      <>
                        <div className=" px-2 md:px-10 cursor-pointer" onClick={closeModal}>
                          <CloseIcon className="w-10 h-10 ml-auto font-primary-semibold text-gray-400 fill-current rounded-lg p-1" />
                        </div>
                        <h1 className="font-primary-bold text-xl text-center ">
                          Enter your details to continue
                        </h1>
                        <div className=" h-auto flex lg:mx-10 mt-5 gap-10 items-start">
                          <div className="mx-auto w-fit  text-center px-1 ">
                            <div>
                              <div className="flex flex-col justify-center items-center ">
                                <div className="flex flex-col gap-4 w-full">
                                  <TextField id="outlined-basic" label="Name" variant="outlined"
                                             onChange={handleInput}/>
                                  {!guestMode && (
                                      <PhoneInput
                                          enableSearch="true"
                                          country="in"
                                          className="font-primary-regular"
                                      />
                                  )
                                  }
                                </div>
                              </div>
                              <div className="my-2.5 md:my-2.5 flex justify-center ">
                                {otpGenerated === false ? (
                                    <ReCAPTCHA
                                        sitekey="6LeQCK0pAAAAAC7R5JrhLx-oI8vnUsFBXUgKX59Y"
                                        onChange={(val) => {
                                          setCaptchaValidated(val);
                                        }}
                                        className="scale-100 origin-center "
                                    />
                                ) : (
                                    <></>
                                )}
                              </div>
                              {/* <div>
                                    <button
                                      className="bg-primary-blue text-base font-primary-medium text-white px-4 py-1 rounded-lg hover:bg-primary-dark-blue"
                                      onClick={handleGeneratingOTP}
                                    >
                                      Get OTP
                                    </button>
                                  </div> */}
                              <button
                                  className="w-full mx-auto bg-primary  hover:bg-blue-800 px-5 py-2 rounded-md font-primary-medium text-white"
                                  onClick={handleNext}
                              >
                                {guestMode ? "Proceed" : "Get OTP"}
                              </button>
                            </div>
                          </div>
                        </div>
                      </>
                    </>
                  </Slider>
                  <div className="w-full md:w-7/12 mx-auto">
                    <div className="relative w-9/12 mx-auto md:w-7/12  flex py-3 items-center">
                      <div className="flex-grow border-t border-gray-400"></div>
                      <span className="flex-shrink mx-4 text-gray-400">or</span>
                      <div className="flex-grow border-t border-gray-400"></div>
                    </div>
                    <>
                      <div className="w-9/12 md:w-7/12 mx-auto">
                        <button
                            className="flex gap-2 w-full justify-center items-center bg-white  border border-gray-300 rounded-lg  px-6 py-2 text-sm font-medium text-gray-800  hover:bg-gray-50 ">
                          <img src={Google} className="w-5 h-5" alt="google icon"/>
                          <span>Continue with Google</span>
                        </button>
                        {guestMode && (
                            <button
                                className="flex gap-2 mt-4 w-full justify-center items-center bg-blue-300 rounded-lg  px-6 py-2 text-sm font-medium text-gray-900  hover:bg-gray-50 "
                                onClick={() => setGuestMode(false)}
                            >
                              <UserIcon className="w-5 h-5 text-gray-800 fill-current" />
                              <span>Proceed as Logged In User</span>
                            </button>)
                        }

                      </div>
                    </>
                  </div>
                  {!guestMode && (
                      <p className="text-gray-500 text-sm mt-2 text-center">Continue as a <a
                          onClick={() => setGuestMode(true)} className="underline text-sky-800">Guest User</a></p>
                  )
                  }
                  <div className="flex justify-center items-center gap-2 mx-auto w-10/12 md:w-7/12 my-4">
                  <SecurityIcon className="w-4 h-4" />
                    <h1 className="text-[10px] font-primary-medium text-gray-500 text-center">
                      We won't share your personal information with anyone for any reason. Your data wil only be used to give you personalized experience in our platform. Still concerned Please feel free to contact us at
                      {" "}<a href="mailto:privacy@tforo.com" className="underline text-sky-800">privacy@tforo.com</a>
                    </h1>
                  </div>
                </Sheet.Content>
              </Sheet.Container>
              <Sheet.Backdrop />
            </Sheet>
          </div>
        </>
      )}
    </>
  );
};

export default RegistrationPopUp;
