import React from 'react'
import Navbar from '../elements/Navbar'
import Footer from '../elements/Footer'
import { motion } from "framer-motion";
import TabWrapper from '../elements/TabWrapper';


export default function ClientsPage() {

    return (
        <div>
            <div className="sticky top-0 z-10">
                <Navbar />
            </div>
            <motion.div animate={{ y: -10 }} transition={{ duration: 0.5 }}>
                <div className='min-h-screen mx-auto bg-white pb-2'>
                    <TabWrapper />
                </div>
            </motion.div>
            <Footer />
        </div>
    )
}


