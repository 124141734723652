import React from 'react'
import  Navbar from '../elements/Navbar'
import  Footer from '../elements/Footer'
import {motion} from 'framer-motion'

function ErrorPage() {
    return (
      <>
        <div className="h-lvh grid grid-cols-1 content-between">
          <div className="sticky top-0 z-50">
            <Navbar />
          </div>
          <motion.div
            animate={{ y: -10 }}
            transition={{ duration: 0.5 }}
            className="error-page"
          >
            <h5 className="error-page__title">OPPS! PAGE NOT FOUND</h5>
            <h1 className="error-page__heading ">404</h1>
            <p className="error-page__text">
              We are sorry, but the page you requested was not found.
            </p>
            <button className="error-page__btn">Back to Home</button>
          </motion.div>
          <Footer />
        </div>
      </>
    );
}

export default ErrorPage