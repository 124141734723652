import React, {useState, useEffect, useRef} from 'react';
import Navbar from '../elements/Navbar';
import SearchBox from '../elements/SearchBox';
import axios from 'axios';
import CONFIG from '../../utils/Config';
import PerfectScrollbar from 'perfect-scrollbar';
import MenuItem from '../elements/MenuItem';
import {motion} from 'framer-motion';
import Footer from '../elements/Footer';
import {useClient} from "../../utils/context/ClientContext";

const SearchPage = () => {
    const [products, setProducts] = useState([]);
    const {tenant} = useClient();
    const myRef = useRef(null);
    let ps;

    useEffect(() => {
        ps = new PerfectScrollbar(myRef.current);
        return () => {
            ps.destroy();
        };
    }, []);

    const handleSearch = (e) => {
        axios({
            url: CONFIG.URL + "public/product/search?searchString=" + e,
            method: "GET",
            headers: {
                "X-TenantID": tenant
            }
        }).then((res) => {
            setProducts(res.data);
        })
        .catch((err) => {
        });
    };

    return (
        <div className="h-lvh grid grid-cols-1 content-between">
            <div className="sticky top-0 z-50">
                <Navbar/>
            </div>
            <motion.div
                animate={{y: -10}}
                transition={{duration: 0.5}}
                className="w-11/12 md:w-8/12 lg:w-6/12 mx-auto relative"
            >
                <div className="w-full  mx-auto mt-5 pt-4 relative">
                    <div className="w-full flex">
                        <SearchBox handleSearch={handleSearch}/>
                    </div>
                </div>
                <div
                    className="mt-5 w-full relative mx-auto md:px-3 rounded-lg"
                    ref={myRef}
                    style={{height: "500px"}}
                >
                    {products.length > 0 ? (
                        products.map((data) => (
                            <MenuItem
                                key={data.item.id}
                                id={data.item.id}
                                name={data.item.name}
                                description={data.item.description}
                                imageUrl={data.item.imageUrl}
                                category={data.item.category.name}
                                rate={data.item.rate}
                            />
                        ))
                    ) : (
                        <h1 className="font-primary-bold text-center p-10">
                            No Data Found
                        </h1>
                    )}
                </div>
            </motion.div>
            <Footer/>
        </div>
    );
};

export default SearchPage;
