import React from 'react'

export default function OrderIcon(props) {
  return (
    <div>
      <svg xmlns="http://www.w3.org/2000/svg" className={props.className} viewBox="0 0 24 24">
        <path fill="currentColor" d="M6.615 21q-.69 0-1.152-.462T5 19.385V8.615q0-.69.463-1.152T6.615 7H8.5v-.5q0-1.458 1.021-2.479T12 3t2.479 1.021T15.5 6.5V7h1.885q.69 0 1.152.463T19 8.615v10.77q0 .69-.462 1.152T17.385 21zM9.5 7h5v-.5q0-1.056-.722-1.778T12 4t-1.778.722T9.5 6.5zm5.5 4q.213 0 .357-.143t.143-.357V8h-1v2.5q0 .213.143.357T15 11m-6 0q.213 0 .357-.143T9.5 10.5V8h-1v2.5q0 .213.143.357T9 11"/>
    </svg>
    </div>
  );
}