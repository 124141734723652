import React from 'react'
import {useExtractColor} from "react-extract-colors";
import VegIcon from '../../utils/icons/VegIcon';
import NonVegIcon from '../../utils/icons/NonVegIcon';
import Stars from "./ui/Stars";

const RatingItem = ({product, data, handleFill}) => {
    const {dominantColor, darkerColor, lighterColor} = useExtractColor(product.imageUrl);
    const customGradientStyle = {
        backgroundImage: 'linear-gradient(to top,' + dominantColor + ', transparent)',
        width: '100%',
        height: '50%',
        position: 'absolute',
        bottom: 0,
        left: 0,
    };

    return (
        <div className={"w-full mx-auto my-3 p-3 md:p-4 shadow-md rounded-2xl border"}>
        <div className="flex justify-between gap-2">
            <div className="flex flex-col gap-3 w-full ml-1">
                <div className={"flex flex-col gap-1"}>
                    <h3 className="text-base md:text-lg font-primary-semibold text-heading">
                        {product.name}
                    </h3>
                    <div className="font-primary-regular">
                        <div className="flex gap-2 items-center">
                            {product.category.name === "Veg" ?
                                (<VegIcon className="w-5 h-5 text-green-600 fill-current"/>) :
                                (<NonVegIcon className="w-5 h-5 text-red-600 fill-current"/>)
                            }
                            <small className="text-gray-800 font-primary-bold text-xs ">
                                {product.subCategory.label}
                            </small>
                        </div>
                    </div>
                    <p className="text-xs md:text-sm text-gray-500 font-primary-regular line-clamp-2 ">
                        <div>{product.description}</div>
                    </p>
                    <Stars
                        id={data.id}
                        filled={data.filled}
                        notFilled={data.notFilled}
                        handleFill={handleFill}
                        type={data.type}
                    />
                </div>
            </div>
            <div className="w-32 h-32 rounded-lg relative overflow-hidden">
                <img
                    src={product.imageUrl}
                    alt=""
                    className="w-full h-full object-cover"
                />
                <div style={customGradientStyle}></div>
            </div>
        </div>
        <div className={"mt-4"}>
            <textarea name="comment" id="text_area"
                      placeholder={"Do you want to write something?"}
                      className={"w-full resize-none border border-gray-300 rounded-2xl p-4 font-primary-regular text-sm"}/>
        </div>
    </div>
    );
}


export default RatingItem;
