import React, { useState } from 'react'
import Navbar from '../elements/Navbar'
import Footer from '../elements/Footer'
import SUCCESS from '../../assets/images/OrderSuccess.gif'
import { useLocation, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion'

export default function OrderSuccessPage() {
    let { state, setState } = useState();
    let location = useLocation();
    const navigate = useNavigate();
    let orderTime = location.state.orderTime;
    let orderId = location.state.orderId;
    let orderDetails = location.state.orderDetails;
    state = {
        image: SUCCESS,
        imageHash: Date.now()
    }
    const redirectToStatusPage = () => {
        navigate("/status", {
            state: {
                orderTime: orderTime,
                orderId: orderId,
                orderDetails: orderDetails
            }
        });
    }
    return (
      <div className="h-lvh grid grid-cols-1 content-between">
        <div className="sticky top-0 z-50">
          <Navbar />
        </div>
        <motion.div
          animate={{ y: -5 }}
          transition={{ duration: 0.3 }}
          className="success-page"
        >
          <img
            src={`${state.image}?${state.imageHash}`}
            alt="order success "
            className="w-48 h-48 md:w-60 md:h-60 mx-auto"
          />
          <h5 className="text-xl md:text-2xl lg:text-3xl font-primary-extrabold text-heading my-2">
            Order <span className='font-gotham-bold'>#{orderId}</span>
          </h5>
          <h5 className="success-page-title">Ordered at {orderTime}</h5>
          <p className="success-page-text">Order Successfully Placed</p>
          <button className="custom-btn" onClick={redirectToStatusPage}>
            View Status
          </button>
        </motion.div>
        <Footer />
      </div>
    );
}
