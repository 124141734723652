import React, { useState, useRef, useEffect } from "react";
import { Link } from 'react-router-dom';
import LOGO from '../../assets/images/CleFactura.png';
import LocationIcon from "../../utils/icons/LocationIcon";
import { useClient } from "../../utils/context/ClientContext";
import { SideNavbar } from "./SideNavbar";
import Location from "./Location";
import { useLocation } from "../../utils/context/LocationContext";

function Navbar() {
  const clientContext = useClient();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isMd, setIsMd] = useState(window.innerWidth >= 768);
  const [isVisible, setIsVisible] = useState(false);
  const [isRegisterVisible, setIsRegisterVisible] = useState(false);
  const [isOffersVisible, setIsOffersVisible] = useState(false);
  const wrapperRef = useRef(null);
  const [isLocationVisible, setIsLocationVisible] = useState(false);
  const { district } = useLocation();
  useEffect(() => {
    window.addEventListener("resize", updateWindowDimensions);
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      window.removeEventListener("resize", updateWindowDimensions);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [clientContext.clients]);

  const updateWindowDimensions = () => {
    setIsMd(window.innerWidth >= 768);
  };

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setIsMenuOpen(false);
      setIsOpen(false);
      setIsVisible(false);
      setIsRegisterVisible(false);
      setIsOffersVisible(false);
    }
  };

  const toggleOpen = () => {
    if (isMd) {
      setIsOpen(!isOpen);
    }
  };  

  const handledropdown = (type) => {
    switch (type) {
      case 1:
        setIsVisible(!isVisible);
        break;
      case 2:
        setIsRegisterVisible(!isRegisterVisible);
        setIsOffersVisible(false);
        break;
      case 3:
        setIsRegisterVisible(false);
        setIsOffersVisible(!isOffersVisible);
        break;
      default:
        break;
    }
  };

  const handleLocation = ()=>{
    setIsLocationVisible(!isLocationVisible);
  }

  return (
    <nav className="w-full shadow-[0_15px_40px_-20px_rgb(40_44_63_/_15%)] bg-white h-20 md:h-20 flex justify-center items-center">
      <div className="flex justify-between items-center w-11/12 md:max-w-7xl 2xl:max-w-full font-primary-regular mx-auto py-1.5 md:py-0">
        <div className="flex justify-between items-center">
          <Link to={"/home"}>
              <div className="flex items-center gap-3">
                <img
                  src={LOGO}
                  alt="cle-factura logo"
                  className="product-logo"
                />
              </div>
          </Link>
        </div>
        <div className={`flex justify-end  ${isMenuOpen ? "block" : "block"}`}>
          <ul className="flex gap-5 justify-center items-center" ref={wrapperRef}>            
            <li>
              <div className="flex gap-2 justify-start md:justify-center items-center cursor-pointer" onClick={handleLocation} >
                <span><LocationIcon className='w-7 h-7 text-primary fill-current'/></span>
                <span className="font-primary-medium">{district !== undefined ? district : "Your Location"}</span>
              </div>
              <Location visibility={isLocationVisible} />
            </li>
            {clientContext.client !== undefined && clientContext.client !== null && 
              (<li>
                <SideNavbar/>
              </li>)
            }
             
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
