import { useNavigate } from "react-router-dom";

function OrderContainer(props) {
  const navigate = useNavigate();
  const orderData = () => {
    navigate("/viewCart", { state: { productData: props.getOrderData() } });
  };
  const { qty, amount } = props;
  return (
    <>
      <div className="z-10 flex justify-between items-center  w-full px-4 py-3 rounded md:rounded-lg mb-3 shadow-lg  text-gray-900 bg-blue-100 ">
        <div>
          <h4 className=" font-primary-semibold">
            {qty} Items Added | ₹ <span className="font-gotham-semibold">{amount}</span>
          </h4>
          <p className="text-xs md:text-sm">+5% GST Applied</p>
        </div>
        <button
          className="font-primary-bold text-white py-2 px-3 bg-primary hover:bg-blue-900 rounded shadow-lg cursor-pointer"
          onClick={orderData}
        >
          Place Order
        </button>
      </div>
    </>
  );
}

export default OrderContainer;
