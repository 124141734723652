import React, {useState} from "react";
import Star from "../../utils/icons/Star";
import {useCart} from "../../utils/context/CartContext";
import RestaurantList from "./RestaurantList";
import VegIcon from "../../utils/icons/VegIcon";
import NonVegIcon from "../../utils/icons/NonVegIcon";

const MenuItemVertical = ({product, type, setId, handleVisibility}) => {
    const cartContext = useCart();

    const handleRestaurantVisibility = () => {
        setId(product.id); // Open the modal
        handleVisibility();
    };

    const addItem = () => {
        let data = {
            product: product,
            quantity: 1,
            rate: product.rate,
            amount: product.rate,
            imageUrl: product.imageUrl,
            rating: product.feedback,
            ratingCount: product.feedbackCount,
        };
        cartContext.addItem(data);
    };

    if (product !== undefined) {
        return (
            <div className={`rounded-2xl shadow-md w-48 ${!type ? "min-w-52 md:min-w-80 md:w-80" : "w-full grow"} border`}>
                <img
                    src={product.imageUrl || ""}
                    alt=""
                    className="w-full h-32 md:h-48 object-cover rounded-t-2xl"
                />
                <div className="px-3 py-2">
                  <span className="text-sm md:text-base font-primary-semibold text-gray-800 overflow-hidden line-clamp-3">
                    {product.name}
                  </span>
                    <div className="flex gap-1 items-center">
                <span className="">
                  {product.category.name === "Veg" ? (
                      <VegIcon className="w-5 h-5 text-green-600 fill-current"/>
                  ) : (
                      <NonVegIcon className="w-5 h-5 text-red-600 fill-current"/>
                  )}
                </span>
                        <small className="text-gray-800 font-primary-bold text-xs ">
                            {product.subCategory.label}
                        </small>
                    </div>
                    <span className="font-primary-medium text-gray-600 text-xs md:text-sm line-clamp-2 overflow-hidden">
                        {product.description}
                    </span>
                    {!type && (<>
                        <div className="flex justify-start items-center gap-1">
                            <Star className="text-yellow-400 w-4 h-4"/>
                            <span className="font-primary-semibold text-sm md:text-base">
                                {product.feedback}
                            </span>
                            <span className="text-gray-600 text-xs md:text-base">
                                ({product.feedbackCount} Ratings)
                            </span>
                        </div>
                        <div className="flex justify-between items-center">
                            <span className="font-gotham-semibold text-base md:text-lg">
                                ₹ {product.rate}
                            </span>
                            <button
                                className="bg-primary font-primary-medium text-white rounded-lg px-2 md:px-2 py-1"
                                onClick={addItem}> + Add
                            </button>
                        </div>
                    </>)}
                    {type && (
                        <div className="flex justify-center p-4">
                            <button
                                className="bg-primary text-white rounded-lg font-primary-medium px-2 md:px-2 py-1 w-full hover:bg-primary-hover"
                                onClick={handleRestaurantVisibility}
                            >
                                Choose Restaurant
                            </button>
                        </div>
                    )}
                </div>
            </div>
        );
    }
};

export default MenuItemVertical;
