import axios from "axios";
import { createContext, useContext, useEffect, useState } from "react";

const LocationContext = createContext();

export const LocationContextProvider = ({children}) => {

    const [district, setDistrict] = useState(() => {
        const district = localStorage.getItem('district');
        if(district !== undefined && district !== null){
            return district;
        }else{
            return 'Thanjavur';
        }
    }); 
    const [state, setState] = useState(() => {
        const state = localStorage.getItem('state');
        if(state !== undefined && state !== null){
            return state;
        }else{
            return 'Tamil Nadu';
        }
    });

    const setLocationAutomatically = () => {
        return new Promise((resolve, reject) => { // Provide resolve and reject
          if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
              (position) => {
                axios({
                  url: `https://maps.googleapis.com/maps/api/geocode/json?key=AIzaSyDRUMHxPFCp1BAtxRQBoFwg0pibV6clzgk&latlng=${position.coords.latitude},${position.coords.longitude}&sensor=true`,
                  method: 'GET'
                })
                  .then((res) => {
                    let locationData = res.data.plus_code.compound_code;
                    let location = locationData.split(" ");
                    let tempLocationData = '';
                    for (let i = 1; i < location.length; i++) {
                      tempLocationData += " " + location[i];
                    }
                    let processedLocationData = tempLocationData.split(",");
                    let district = processedLocationData[0].replace(" ","");
                    let state = processedLocationData[1].replace(" ","");
                    setDistrict(district);
                    setState(state);
      
                    resolve({ district, state }); // Resolve the Promise
                  })
                  .catch((err) => {
                    console.error(err);
                    reject(err); // Reject if axios fails
                  });
              },
              (err) => {
                alert("Location access is not available kindly give the access or select location manually to provide a seamless experience.");
                reject(err); // Reject if geolocation fails
              }
            );
          } else {
            alert('Location access is not available kindly give the access or select location manually to provide a seamless experience.');
            reject(new Error('Geolocation not supported')); // Reject if geolocation not supported
          }
        });
      }      

    useEffect(() => {                   
        localStorage.setItem('district', district);
        localStorage.setItem('state', state);                                 
    }, [district, state]);

    return <LocationContext.Provider value={{district, state, setDistrict, setState, setLocationAutomatically}}>{children}</LocationContext.Provider>
}

export const useLocation = () => {
    return useContext(LocationContext);
}
