import React, { useEffect, useRef, useState } from 'react'
import FilterIcon from '../../utils/icons/FilterIcon'
import CONFIG from '../../utils/Config';
import Skeleton from 'react-loading-skeleton';
import axios from 'axios';
import { useFilters } from '../../utils/context/FiltersContext';
import { useClient } from '../../utils/context/ClientContext';

function Filter(props) {
  const { tenant } = useClient();
  const filtersContext = useFilters();
  const [filters, setFilters] = useState([]);
  const [loading, setLoading] = useState(true);  
  const loadFilters = () => {
    axios({
      url: CONFIG.URL + "public/product/getFilters",
      method: 'GET',
      headers: {
        "X-TenantID": tenant
    }
    })
      .then((res) => {
        setLoading(false);
        setFilters(res.data);
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
      });
  }
  useEffect(() => {
    loadFilters();
  }, [])
  const handleClick = (e) => {
    if (e.target.id !== undefined && e.target.id !== null && e.target.id !== "") {
      let selected = false;
      if (e.target.className.toString().indexOf("bg-blue-100") === -1) {
        e.target.className = e.target.className + " bg-blue-100 border-primary";
        selected = true;
      } else {
        e.target.className = e.target.className.replaceAll(" bg-blue-100 border-primary", "");
      }
      let type = e.target.id.toString().split("@CF@");
      props.handleProductFilter(parseInt(type[0]), parseInt(type[1]), selected);
    }

  }
  return (
    <>
      <div className="flex gap-2 items-center justify-start mt-4 overflow-x-auto scrollbar-hide">
        {loading && (
          <>
            {" "}
            <Skeleton
              height={"32px"}
              style={{ borderRadius: "20px" }}
              width={"100px"}
              containerClassName="avatar-skeleton"
            />
          </>
        )}
        {filters.length > 0 &&(
          <div
            className="text-gray-800 border border-gray-200 px-6 py-1 flex items-center gap-1.5  cursor-pointer rounded-lg text-sm font-primary-medium whitespace-nowrap"
            onClick={() => {
              filtersContext.handleAdvancedFilterVisibility();
            }}
          >
            <FilterIcon className="w-5 h-5" />

            <div className="text-center">Advanced Filters</div>
          </div>
        )}

        {loading && (
          <>
            <Skeleton
              height={"32px"}
              style={{ borderRadius: "20px" }}
              width={"100px"}
              containerClassName="avatar-skeleton"
            />
            <Skeleton
              height={"32px"}
              style={{ borderRadius: "20px" }}
              width={"100px"}
              containerClassName="avatar-skeleton"
            />
            <Skeleton
              height={"32px"}
              style={{ borderRadius: "20px" }}
              width={"100px"}
              containerClassName="avatar-skeleton"
            />
            <Skeleton
              height={"32px"}
              style={{ borderRadius: "20px" }}
              width={"100px"}
              containerClassName="avatar-skeleton"
            />
          </>
        )}

        <div onClick={handleClick} className="flex gap-2">
          {filters.length > 0
            ? filters.map((e, index) => (
                <div
                  className="flex gap-2 border rounded-lg px-4 md:px-6 py-1 text-sm font-primary-medium justify-center items-center cursor-pointer flex-grow whitespace-nowrap"
                  key={index}
                  id={e.id}
                >
                  <img
                    src={
                      e.metadata !== null
                        ? require(`../../assets/images/${e.metadata}`)
                        : ""
                    }
                    className="w-5 h-5"
                    alt="veg icon"
                  />
                  {e.label}
                </div>
              ))
            : null}
        </div>
      </div>
    </>
  );
}

export default Filter