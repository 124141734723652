import { React, createContext, useContext, useEffect, useState } from 'react';

const FiltersContext = createContext(null);

export const FiltersContextProvider = ({ children }) => {
    const [advancedFiltersVisibility, setAdvancedFilterVisibility] = useState(false);
    const [filtersData, setFiltersData] = useState(() => {
        const savedFiltersData = sessionStorage.getItem('filtersData');
        return savedFiltersData ? JSON.parse(savedFiltersData) : null;
    });

    useEffect(() => {        
        sessionStorage.setItem('filtersData', JSON.stringify(filtersData));
    }, [filtersData,advancedFiltersVisibility]);

    const setFilters = (filters) => {
        setFiltersData(filters);
    }  
    const resetFilters = () => {
        setFiltersData(null);
    }
    const handleAdvancedFilterVisibility = () => {        
        setAdvancedFilterVisibility(advancedFiltersVisibility !== true);
      }
    return <FiltersContext.Provider value={{ filtersData, advancedFiltersVisibility, setFilters, resetFilters, handleAdvancedFilterVisibility }}>{children}</FiltersContext.Provider>
}

export const useFilters = () => {
    return useContext(FiltersContext)
}