import React, { useState } from 'react'
import Navbar from '../elements/Navbar'
import Footer from '../elements/Footer'
import OrderList from '../elements/OrderList'
import { motion } from "framer-motion";
import RatingPopup from '../elements/RatingPopup';
import toast, { Toaster } from 'react-hot-toast';

export default function OrdersPage() {
  const [ratingVisibility, setRatingVisibility] = useState(false);
  const [orderId, setOrderId] = useState(null);
  const handleVisibility = (orderId) => {
    if(ratingVisibility === true){
      setRatingVisibility(false);
      return;
    }
    setOrderId(orderId);
    if(orderId !== undefined && orderId !== null){      
      setRatingVisibility(ratingVisibility == true ? false : true);
    }    
  }
  const triggerToast = (type) => {
    if(type === 1){
      toast.success("Thanks for rating our Product");    
    }else{
      toast.error("Something went wrong try again later!");            
    }
            
  }
    return (
      <div className="h-lvh w-full grid grid-cols-1 content-between">
        <Toaster toastOptions={{ duration: 4000 }} />
        <div className="sticky top-0 z-50">
          <Navbar />
        </div>
        <motion.div animate={{ y: -10 }} transition={{ duration: 0.5 }}>
          <div className=" mx-auto flex flex-col justify-between align-top gap-3  w-full lg:w-10/12 py-3 rounded md:rounded-lg mb-3 mt-6">            
            <OrderList handleVisibility={handleVisibility} />
          </div>
        </motion.div>        
        <Footer />   
        {ratingVisibility === true && (<RatingPopup visibility={ratingVisibility} handleVisibility={handleVisibility} orderId={orderId} toast={triggerToast}/>)}        
      </div>
    );
}
