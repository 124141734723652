import React from 'react';
import { useExtractColor } from "react-extract-colors";
import Star from '../../utils/icons/Star';
import VegIcon from "../../utils/icons/VegIcon";
import NonVegIcon from "../../utils/icons/NonVegIcon";

const CartItem = ({
    productId,
    imageUrl,
    categoryName,
    productName,
    rate,
    quantity,    
    icons,
    rating,
    ratingCount,
    handleQty,
    amount
}) => {
    const { dominantColor, darkerColor, lighterColor } = useExtractColor(imageUrl);
    const customGradientStyle = {
        backgroundImage: 'linear-gradient(to top,' + darkerColor + ', transparent)',
        width: '100%',
        height: '50%',
        position: 'absolute',
        bottom: 0,
        left: 0,
    };
    return (
      <table className="w-full  table-fixed text-subheading  border-separate border-spacing-y-1">
        <tbody>
          <tr className="flex md:gap-2">
            <td>
              <div className="w-20 h-20 md:w-28 md:h-28  rounded-lg relative overflow-hidden hidden md:block">
                <img
                  src={imageUrl}
                  alt=""
                  className="w-full h-full object-cover"
                />
                <div style={customGradientStyle}></div>
                <span className="absolute bottom-0.5 right-0.5 p-1 rounded">
                  {categoryName === "Veg" ? (
                    <VegIcon className="w-5 h-5 text-green-600 fill-current" />
                  ) : (
                    <NonVegIcon className="w-5 h-5 text-red-600 fill-current" />
                  )}
                </span>
              </div>
            </td>
            <td className="flex-grow items-stretch *:mb-1 ml-4 md:ml-0">
              <p className="text-sm md:text-lg font-primary-bold text-paragraph line-clamp-1">
                {productName}
              </p>
              <span className="block text-sm md:text-lg font-gotham-semibold">
                ₹ {rate}
              </span>
              <div className="flex items-center gap-1 font-gotham-normal text-xs">
                <Star className="text-yellow-400 w-4 h-4" />
                <p className="flex gap-1">
                  {rating}<span>({ratingCount} Ratings)</span>
                </p>
              </div>
            </td>
            <td className="w-28 self-center justify-end ">              
              <div className="w-24  px-2  bg-blue-50 border-2 border-primary flex gap-2 justify-between items-center  rounded-lg text-base font-primary-medium mx-auto">
              <button
                id="reduce"
                onClick={handleQty}
                data-product={productId}
                className="font-primary-semibold text-2xl pb-1"
              >
                -
              </button>
              <p className="w-7 md:w-10 text-center md:px-2 text-base md:text-xl font-gotham-medium">
                {quantity}
              </p>
              <button
                id="increase"
                onClick={handleQty}
                className="font-primary-semibold text-2xl pb-1"
                data-product={productId}
              >
                +
              </button>
            </div>
              <p className="my-1 text-base font-gotham-semibold text-subheading text-center">
                ₹ {amount}
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    );
};

export default CartItem;
