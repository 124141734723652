import React, {useEffect, useState} from 'react'
import ClientItem from './ClientItem'
import axios from 'axios';
import CONFIG from '../../utils/Config';
import {encryptData} from '../../utils/EncryptionUtil'
import {useLocation} from '../../utils/context/LocationContext';
import SearchBox from './SearchBox';


function ClientsList() {
    const [clients, setClients] = useState([]);
    const {district, state} = useLocation()
    useEffect(() => {
        axios({
            url: CONFIG.URL + "public/client/getClients?state=" + state + "&district=" + district,
            method: 'GET',
            withCredentials: true,
            headers: {
                'X-TenantID': encryptData(CONFIG.DEFAULT_TENANT)
            }
        }).then((res) => {
            setClients(res.data);
        }).catch((err) => {
            alert('Error: ' + err.message);
            console.error(err);
        })
    }, []);
    return (
        <div className='w-11/12 px-2 md:w-9/12 mx-auto'>
            <div className=" overflow-y-auto py-2  grid grid-auto-fit-lg gap-2 md:gap-4 ">
                {clients !== null && clients.length > 0 && (
                    clients.map((e, index) => {
                        return <ClientItem client={e} key={index}/>
                    })
                )}
            </div>
        </div>
    )
}

export default ClientsList