import LocationIcon from "../../utils/icons/LocationIcon";
import React from "react";
import Star from "../../utils/icons/Star";
import AiIcon from "../../utils/icons/AiIcon";
import {ArrowRightIcon} from "@heroicons/react/24/outline";
import {useClient} from "../../utils/context/ClientContext";
import {useNavigate} from "react-router-dom";
import {encryptData} from "../../utils/EncryptionUtil";

const Card = ({client}) => {
    const clientContext = useClient();
    const navigate = useNavigate();
    const handleRedirect = () => {
        clientContext.setTenant(encryptData(client.tenantId));
        clientContext.setName(client.name);
        clientContext.setClient(client);
        navigate("/home");
    }
    return (
        <>
            <div className="border border-gray-200 rounded-lg shadow-md ">
                <div className="p-2 md:p-4 flex justify-start items-center">
                    <img
                        src={client.imageUrl}
                        alt={client.name}
                        className="w-24 h-24 md:w-32 md:h-32 rounded-md object-cover mr-2 md:mr-4"
                    />
                    <div className="md:*:my-1 flex-grow">
                        <h2 className="text-base md:text-lg font-primary-medium text-gray-800">
                            {client.name}
                        </h2>
                        <p className="text-xs md:text-sm text-gray-600 line-clamp-2 w-fit">
                            {client.description}
                        </p>
                        <p className="text-sm text-gray-500">
                            Phone: {client.contactNumber}
                        </p>
                        {client?.rating.toString().length > 0 && (
                            <div className="flex justify-start items-center gap-1 my-1 ">
                                <Star className="text-yellow-600 w-4 h-4"/>
                                <span className="font-primary-semibold text-xs">{client.rating}</span>
                                <span className="text-gray-600 text-xs">(200) Ratings</span>
                            </div>)
                        }
                    </div>
                    <div className="cursor-pointer rounded-lg">
                        <div className={'flex flex-col items-center justify-center gap-1'}>
                            <LocationIcon className="w-8 h-8 text-primary p-1 rounded-md fill-current"/>
                            <small className={"font-primary-medium md:font-primary-semibold text-center text-xs"}>
                                Map</small>
                        </div>
                    </div>

                </div>
                <div className={"flex justify-between items-center px-4 border-t border-gray-200"}>
                    {client?.comment.toString().length > 0 ? (
                        <div className="flex flex-col justify-start items-start gap-1 pt-2 pb-4">
                                    <span
                                        className="font-primary-semibold text-sm flex items-start gap-1">{"What people think about this product"}
                                        <AiIcon className="w-5 h-5 text-primary-dark-blue fill-current "/></span>
                            <span
                                className="text-gray-600 text-xs">{client.comment}</span>
                        </div>) : (<div></div>)
                    }
                    <div>
                        <button
                            className="text-white rounded-lg font-primary-medium px-2 md:px-2 py-1 hover:bg-gray-300 flex justify-between items-center"
                            onClick={handleRedirect}>
                            <ArrowRightIcon className={'text-primary-dark-blue w-5 md:w-8 h-5 md:h-8'}/>
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Card