import React, { useEffect, useState } from 'react';
import axios from 'axios';
import CONFIG from '../../utils/Config';
import { motion } from 'framer-motion'
import { useProduct } from '../../utils/context/ProductContext';
import { useFilters } from '../../utils/context/FiltersContext';
import {useClient} from "../../utils/context/ClientContext";

export const AdvancedFilters = () => {
    const productContext = useProduct();
    const filtersContext = useFilters();
    const { tenant } = useClient();
    const [filterData, setFilterData] = useState(null);
    const [sortType, setSortType] = useState(null);
    const [filters, setFilters] = useState([]);
    const [filterMap, setFilterMap] = useState(null);

    useEffect(() => {
        axios({
            url: CONFIG.URL + "public/product/getAdvancedFilters",
            method: 'GET',
            withCredentials: false,
            headers: {
                "X-TenantID": tenant
            },
        })
            .then((res) => {
                setFilters(res.data);
            })
            .catch((err) => {
                console.error(err);
            });
    }, []);
    const handleAdvancedFilter = () => {
        productContext.loadProductList({ filter: filterData })        
        filtersContext.handleAdvancedFilterVisibility();
    }
    const handleClick = (e, filterType, data) => {
        let selected = false;
        if (e.target.className.toString().indexOf("bg-sky-100") === -1) {
            selected = true;
            e.target.className = e.target.className + " bg-sky-100";
        } else {
            e.target.className = e.target.className.replaceAll("bg-sky-100", "");
        }
        if (filterType === 1) {
            setSortType(data);
        }
        let map = filterMap;
        if (map === null || map === undefined) {
            map = new Map();
        }
        //is Multi Select
        if (filterType !== 1) {
            let filterIds;
            if (map.has(filterType)) {
                filterIds = map.get(filterType);
            } else {
                filterIds = [];
            }
            if (selected === true) {
                filterIds.push(data);
            } else {
                filterIds = filterIds.filter((e) => {
                    return e !== data
                })
            }
            map.set(filterType, filterIds);
        } else {
            map.set(filterType, data);
        }
        setFilterMap(map);
        let tempFilterData = { ...filterData };
        switch (filterType) {
            case 1:
                if (tempFilterData.sortBy === null || tempFilterData.sortBy === undefined) {
                    tempFilterData = {
                        ...tempFilterData,
                        sortBy: data
                    }
                } else {
                    tempFilterData.sortBy = data;
                }
                break;

            case 2:
                //Categories
                if (tempFilterData.category === null || tempFilterData.category === undefined) {
                    tempFilterData = {
                        ...tempFilterData,
                        category: [data]
                    }
                } else {
                    let category = filterData.category;
                    if (selected === true) {
                        category.push(data);
                    } else {
                        category = category.filter((e) => {
                            return e !== data
                        });
                    }
                    tempFilterData.category = category;
                }
                break;
            case 3:
                //Cuisines
                if (tempFilterData.subCategory === null || tempFilterData.subCategory === undefined) {
                    tempFilterData = {
                        ...tempFilterData,
                        subCategory: [data]
                    }
                } else {
                    let subCategory = filterData.subCategory;
                    if (selected === true) {
                        subCategory.push(data);
                    } else {
                        subCategory = subCategory.filter((e) => {
                            return e !== data
                        });
                    }
                    tempFilterData.subCategory = subCategory;
                }
                break;
            case 4:
                //Preparation Time     
                if (tempFilterData.timeId === null || tempFilterData.timeId === undefined) {
                    tempFilterData = {
                        ...tempFilterData,
                        timeId: [data]
                    }
                } else {
                    let timeId = filterData.timeId;
                    if (selected === true) {
                        timeId.push(data);
                    } else {
                        timeId = timeId.filter((e) => {
                            return e !== data
                        });
                    }
                    tempFilterData.timeId = timeId;
                }
                break;
        }
        setFilterData(tempFilterData);        
    };

    return (
        <> {filtersContext.advancedFiltersVisibility && (<motion.div animate={{ y: -10 }} transition={{ duration: 0.7 }}
            id="progress-modal"
            className="w-full fixed top-0 right-0 bottom-0 left-0 z-50 flex py-24 md:pt-36 h-lvh bg-gray-800 bg-opacity-50 overflow-y-auto"
            role="dialog"
            aria-modal="true"
        ><div className={`flex flex-col gap-5 z-50 border p-5 md:p-10 w-11/12 md:w-8/12 mx-auto h-fit rounded-2xl bg-white`}>
                <h1 className='font-primary-bold text-base md:text-xl'>
                    Advanced Filters
                </h1>
                {filters.length > 0 ? (
                    filters.map((filter) => (
                        <>
                            <div className='font-primary-medium *:mb-2 cursor-pointer' id='sortBy'>
                                <h1 className='text-sm font-primary-bold py-2'>
                                    {filter.label}
                                </h1>
                                {filter.isMultiSelect === 0 ? (
                                    <div className='flex gap-2 flex-wrap'>
                                        {filter.data.map((filterItem) => (
                                            <div className={`border rounded-2xl px-4 py-1 text-sm text-nowrap ${sortType === filterItem.id ? 'bg-green-100' : ''}`} onClick={(e) => handleClick(e, filter.id, filterItem.id)}>
                                                {filterItem.label}
                                            </div>
                                        ))}
                                    </div>
                                ) : (
                                    <div className='flex gap-2 flex-wrap'>
                                        {filter.data.map((filterItem) => (
                                            <div className={`border rounded-2xl px-4 py-1 text-sm text-nowrap ${filterMap !== null && filterMap !== undefined && filterMap.has(filter.id) === true && filterMap.get(filter.id).includes(filterItem.id) === true ? 'bg-sky-100' : ''}`} onClick={(e) => handleClick(e, filter.id, filterItem.id)}>
                                                {filterItem.label}
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                            <hr />
                        </>
                    ))
                ) : (
                    <></>
                )}
                <div className='flex items-center gap-4 justify-end'>
                    <button className='bg-gray-700 px-4 py-1 text-white font-primary-medium rounded-lg' onClick={() => {filtersContext.handleAdvancedFilterVisibility()}}>Cancel</button>
                    <button className='bg-green-500 px-4 py-1 text-white font-primary-bold rounded-lg' onClick={handleAdvancedFilter}>Apply</button>
                </div>
            </div></motion.div>)}
        </>
    );
};
