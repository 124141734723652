import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { ProgressBar } from '../elements/ProgressBar';
import Navbar from '../elements/Navbar';
import Footer from '../elements/Footer';
import OrderDetails from '../elements/OrderDetails';
import { useLocation } from 'react-router-dom';

const StatusPage = () => {
  const location = useLocation();
    const { orderDetails } = location.state;
    return (
        <div className='h-lvh grid grid-cols-1 content-between'>
            <Navbar />
            <motion.div animate={{ y: -10 }} transition={{ duration: 0.5 }} className='mx-auto w-full md:w-8/12 lg:w-6/12 px-4 py-3 rounded-2xl mb-3 '>
                <ProgressBar orderState={orderDetails}/>
                <OrderDetails orderId={orderDetails.orderId} orderTime={orderDetails.orderTime} orderData={orderDetails} />
            </motion.div>
            <Footer />
        </div>
    );
};

export default StatusPage;