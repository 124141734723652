import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import CONFIG from '../../utils/Config';
import OrderItem from './OrderItem';
import icons from "../../utils/Icons";
import Order from '../../assets/images/Order.svg';
// import PdfDocument from '../elements/PdfDocument';
// import { PDFViewer } from '@react-pdf/renderer';
import { useClient } from '../../utils/context/ClientContext';

const OrderList = ({handleVisibility}) => {
  const { tenant, client } = useClient();
  const loadingRef = useRef(null);
  const [orders, setOrders] = useState([]);
  
  const loadOrders = () => {
    let tokenId = sessionStorage.getItem("tokenId");
    if(tokenId === undefined){
      tokenId = "";
    }
    axios({
      url: CONFIG.URL + "public/order/getOrders?tokenId=" + tokenId,
      headers: {
        "X-TenantID": tenant
      },
      timeout:3000
    })
      .then((res) => {        
        loadingRef.current.classList = "hidden";
        setOrders(res.data);
      })
      .catch((err) => {
        loadingRef.current.classList = "hidden";
        console.error(err);
      });
  };

  useEffect(() => {
    loadOrders();
  }, []);
  
  return (
    <div className="md:px-10 w-full xl:w-9/12 mx-auto mt-2">      
      <div className="w-full relative mx-auto px-2 md:px-3 overflow-y-auto scrollbar-hide h-auto">                    
        <div ref={loadingRef} className="">
          {icons.loading}
        </div>        
        {orders.length > 0 && (
          <h1 className="font-primary-bold text-xl text-center text-heading mb-2 ">
            Your Orders
          </h1> )}
        {orders.length > 0 ? (
          orders.map((data) => (  
          <>                     
            <React.Fragment key={data.id}>
              <OrderItem
                orderId={data.orderId}
                orderTime={data.orderTime}
                orderStatus={data.state}
                token={data.token}
                amount={data.amount}
                orderDetails={data}
                handleVisibility={handleVisibility}
                rating={data.feedback}          
                clientData={client}
                orderData={data}    
              /> 
              {/* <PDFViewer style={{width:'100%', height:"100vh"}}>
                <PdfDocument orderData={data} clientData={clientContext.client}/>
              </PDFViewer>  */}
              <hr/>                           
            </React.Fragment>   
            </>
          ))          
        ) : (
          <div className='flex flex-1 justify-center items-center mt-16 mb-40 md:my-40 '>
            <h1 className="font-primary-medium text-center">
              <img src={Order} className='w-80 h-80 mx-auto' alt="No Order Found" />
              No Orders Available
            </h1>
          </div>            
        )}
      </div>
    </div>
  );
};

export default OrderList;
