import React, { useState, useEffect } from 'react';
import Sheet from 'react-modal-sheet';
import CloseIcon from '../../utils/icons/CloseIcon';
import GpsIcon from '../../utils/icons/GpsIcon';
import { useLocation } from '../../utils/context/LocationContext';
import {Dialog, Button, DialogActions, DialogTitle, DialogContent, DialogContentText} from "@mui/material";

const states = ["Tamil Nadu", "Kerala"];
const districts = {
    "Tamil Nadu": ["Chennai", "Thanjavur", "Thiruvarur"],
    "Kerala": ["Wayanad", "Ernakulam"],
};

const Location = ({ visibility }) => {
    const { district, state, setDistrict, setState, setLocationAutomatically } = useLocation();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedState, setSelectedState] = useState(state);
    const [selectedDistrict, setSelectedDistrict] = useState(district);
    const [isDistrictDropdownEnabled, setIsDistrictDropdownEnabled] = useState(state!==undefined);
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(!open);

    useEffect(() => {
        setIsModalOpen(visibility);
    }, [visibility]);

    useEffect(() => {
    }, [selectedDistrict, selectedState]);

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const handleAutomaticLocationDetection = () => {                
        setLocationAutomatically()
        .then((data) => {
            setSelectedState(data.state);
            setIsDistrictDropdownEnabled(true);
            setSelectedDistrict(data.district);
        });
    }

    const handleStateChange = (e) => {
        const selected = e.target.value;
        setSelectedState(selected);
        setIsDistrictDropdownEnabled(!!selected);
        setSelectedDistrict('');
    };

    const handleDistrictChange = (e) => {
        setSelectedDistrict(e.target.value);
    };

    const handleLocationUpdate = () => {
        if (selectedState && selectedDistrict) {
            setDistrict(selectedDistrict);
            setSelectedState(selectedState);
            closeModal();
        }
    };

    return (
        <>
            {isModalOpen && (
                <div>
                    <Sheet
                        isOpen={isModalOpen}
                        onClose={closeModal}
                        detent="content-height"
                        className="w-full md:w-8/12 mx-auto"
                    >
                        <Sheet.Container>
                            <Sheet.Header />
                            <Sheet.Content>
                                <div className="hidden md:block px-2 md:px-10 cursor-pointer" onClick={closeModal}>
                                    <CloseIcon className="w-10 h-10 ml-auto font-primary-semibold text-gray-400 fill-current rounded-lg p-1" />
                                </div>
                                <h1 className="font-primary-bold text-xl text-center">
                                    Choose Your Location Here
                                </h1>
                                <div className="mx-auto w-full md:w-9/12 mb-10  gap-4 p-4 *:my-6 ">
                                    <div>
                                        <label htmlFor="state"
                                               className="block text-sm font-primary-medium text-gray-700">State</label>
                                        <select
                                            id="state"
                                            value={selectedState}
                                            onChange={handleStateChange}
                                            className="mt-1 block w-full px-3 py-2 font-primary-regular bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                        >
                                            <option value="">Select a State</option>
                                            {states.map((state) => (
                                                <option key={state} value={state}>
                                                    {state}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div>
                                        <label htmlFor="district" className="block text-sm text-gray-700 font-primary-medium">District</label>
                                        <select
                                            id="district"
                                            value={selectedDistrict}
                                            onChange={handleDistrictChange}
                                            disabled={!isDistrictDropdownEnabled}
                                            className={`mt-1 block w-full font-primary-regular px-3 py-2 border ${isDistrictDropdownEnabled ? 'bg-white border-gray-300' : 'bg-gray-100 border-gray-200'
                                                } rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                                        >
                                            <option value="">Select a District</option>
                                            {isDistrictDropdownEnabled && districts[selectedState] &&
                                                districts[selectedState].map((district) => (
                                                    <option key={district} value={district}>
                                                        {district}
                                                    </option>
                                                ))}
                                        </select>
                                    </div>
                                    <div>
                                        <button
                                            type="button"
                                            disabled={!selectedDistrict}
                                            className={`px-3 py-1 rounded-md text-white font-primary-medium text-sm md:text-base ${selectedDistrict ? 'bg-blue-800 hover:bg-blue-700' : 'bg-gray-400 cursor-not-allowed'
                                                }`}
                                            onClick={handleLocationUpdate}
                                        >
                                           Choose Location
                                        </button>
                                    </div>
                                    <div className="mx-auto flex items-center text-center gap-2 pt-2 font-primary-medium cursor-pointer" onClick={handleOpen}>
                                        <GpsIcon className='w-7 h-7 cursor-pointer text-blue-800' />
                                        <p>Detect your location automatically</p>
                                    </div>
                                    <small className='text-gray-500'>*This may ask you to enable your location access to get your location data. This will be stored in your browser to improve your experience with our application.</small>
                                </div>
                                <div style={{zIndex: "3"}}>
                                    <Dialog
                                        open={open}
                                        onClose={handleOpen}
                                        aria-labelledby="responsive-dialog-title"
                                    >
                                        <DialogTitle id="responsive-dialog-title">
                                            {"Are you sure you want to logout?"}
                                        </DialogTitle>
                                        <DialogContent>
                                            <DialogContentText>
                                                Your data might be lost and you have to log in again to gain access, If you wish to proceed click confirm, else click cancel.
                                            </DialogContentText>
                                        </DialogContent>
                                        <DialogActions>
                                            <Button autoFocus onClick={handleOpen}>
                                                Cancel
                                            </Button>
                                            <Button onClick={handleAutomaticLocationDetection()} autoFocus>
                                                Confirm
                                            </Button>
                                        </DialogActions>
                                    </Dialog>
                                </div>
                            </Sheet.Content>
                        </Sheet.Container>
                        <Sheet.Backdrop />
                    </Sheet>
                </div>
            )}
        </>
    );
};

export default Location;
