import React, { useEffect, useState } from 'react';
import discuss from "../../assets/images/Feedback.svg"
import axios from "axios";
import icons from '../../utils/Icons'
import Stars from './ui/Stars';
import CONFIG from '../../utils/Config';



export default function FeedbackPopup(props) {
    const [isModalOpen, setIsModalOpen] = useState(true);
    const [questionData, setQuestionData] = useState(Array(1));
    const [comment, setComent] = useState();
    useEffect(() => {
        let questionDataArray = [];
        setIsModalOpen(props.visibility);
        axios({
            url: CONFIG.URL + "client/getQuestions",
            method: "GET",
            headers: {
                'key': CONFIG.KEY,
                'token': CONFIG.TOKEN,
                  "X-TenantID": CONFIG.TENANT_ID
                }
        }).then((res) => {
            res.data.map((e) => {                
                let data = {
                    id: e.id,
                    question: e.question,
                    type: e.type,
                    filled: 0,
                    notFilled: 5,
                    comment: ""
                }
                questionDataArray.push(data);
            });
            setQuestionData(questionDataArray);
        }).catch((err) => {
            console.error(err);
        })
    }, []);
    const openModal = () => {
        setIsModalOpen(true);
    };
    const closeModal = () => {
        setIsModalOpen(false);
        props.handleVisibility();
    };
    const handleFill = (type, count, fillType, questionId) => {
        let question = questionData.filter((e) => { return e.id == questionId });
        let tempQuestions = questionData.filter((e) => { return e.id != questionId });
        let filled = question[0].filled;
        if (fillType == 2) {
            count = filled + count;
        } else {
            count = count;
        }
        if (count !== parseInt(filled)) {
            question[0].filled = count;
            question[0].notFilled = (5 - count);
        }
        tempQuestions.push(question[0]);
        tempQuestions.sort((a, b) => { return a.id - b.id });
        setQuestionData(tempQuestions);
    }
    const handleComment = (e) => {
        setComent(e.target.value);
    }
    const handleFeedback = (f) => {
        let tempData = questionData.filter((e) => {
            return e.type == 1
        }); 
        let feedbackData = []; 
        tempData.map((e) => {            
            let tempQuestionData = {
                id: e.id,
                rating: e.filled
            }
            feedbackData.push(tempQuestionData);
        })      
        let data = {
            feedbackData: feedbackData,
            commentData:{
                comment:comment
            }       
        }
        axios({
            url:CONFIG.URL+"client/feedback",
            method:"POSt",
            data:data,
            headers:{
                'key':CONFIG.KEY,
                'token':CONFIG.TOKEN
            }
        }).then((res) => {
            alert("Feedback submitted successfully");        
            props.stopEvent();
            sessionStorage.setItem("FEEDBACK_SUBMITTED",true);            
            closeModal();
            f.preventDefault();
        }).catch((err) => {
            console.error(err);
        })
    }
    return (
      <>
        {isModalOpen && (
          <div
            id="progress-modal"
            className="w-full overflow-y-auto fixed top-0 right-0 bottom-0 left-0 z-[100]  flex items-center justify-center bg-gray-800 bg-opacity-50"
            role="dialog"
            aria-modal="true"
          >
            <div className="w-11/12 md:w-9/12 lg:w-7/12 mt-10 md:mt-10  relative bg-white rounded-2xl shadow">
              <button
                onClick={closeModal}
                className="absolute top-3 right-3 text-white  bg-red-600 hover:bg-red-500  rounded-lg text-sm w-6  h-6  flex justify-center items-center "
                type="button"
              >
                <svg
                  className="w-4 h-4"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
              <div className="h-auto md:h-72 block  md:flex justify-center items-center *:w-full *:md:w-6/12 mx-5 md:mx-10 mt-8 md:mt-20">
                <div className="px-2 md:px-10 hidden lg:block ">
                  <img
                    src={discuss}
                    alt="discuss"
                    className="w-36 md:w-96 h-36 md:h-96 mx-auto "
                  />
                </div>
                <div className=" box-border text-left px-2 pt-6 md:p-5 mt-6 md:mt-14">
                  {questionData.length > 0 ? (
                    questionData.map((e) => {
                      return (
                        <div key={e.id} id={e.id}>
                          <h1 className="w-full mb-2 font-primary-regular text-sm md:text-base md:flex md:justify-start md:items-center text-blue-gray-900">
                            {e.question}
                          </h1>
                          {e.type === 1 ? (
                            <div className="mt-1 mb-6 flex items-center  ">
                              <Stars
                                filled={e.filled}
                                notFilled={e.notFilled}
                                key={e.id}
                                id={e.id}
                                handleFill={handleFill}
                                type={1}
                              />
                            </div>
                          ) : (
                            <div className="mt-1 mb-6 flex items-center  ">
                              <textarea
                                className="w-full h-24 border rounded-xl font-primary-regular text-green-400 text-sm md:text-base py-1 px-2 mt-2 resize-none focus:outline-none focus:border-sky-600 focus:shadow-none placeholder:text-sm"
                                placeholder="Your comment here"
                                value={comment}
                                onChange={handleComment}
                              ></textarea>
                            </div>
                          )}
                        </div>
                      );
                    })
                  ) : (
                    <></>
                  )}
                  <button
                    className="w-auto h-9 flex gap-1 items-center justify-center py-3 px-4 text-lg font-primary-bold text-white bg-green-600 hover:bg-green-500 rounded-lg shadow-lg cursor-pointer"
                    onClick={handleFeedback}
                  >
                    <span>{icons.send}</span> <span>Submit</span>
                  </button>
                </div>
              </div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 1440 280"
                className="rounded-b-2xl "
              >
                <path
                  fill="#fef08a"
                  fill-opacity="1"
                  d="M0,96L26.7,101.3C53.3,107,107,117,160,138.7C213.3,160,267,192,320,208C373.3,224,427,224,480,218.7C533.3,213,587,203,640,192C693.3,181,747,171,800,186.7C853.3,203,907,245,960,229.3C1013.3,213,1067,139,1120,138.7C1173.3,139,1227,213,1280,229.3C1333.3,245,1387,203,1413,181.3L1440,160L1440,320L1413.3,320C1386.7,320,1333,320,1280,320C1226.7,320,1173,320,1120,320C1066.7,320,1013,320,960,320C906.7,320,853,320,800,320C746.7,320,693,320,640,320C586.7,320,533,320,480,320C426.7,320,373,320,320,320C266.7,320,213,320,160,320C106.7,320,53,320,27,320L0,320Z"
                ></path>
              </svg>
            </div>
          </div>
        )}
      </>
    );
}
