import { useState, useEffect, createRef } from 'react';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { StyledEngineProvider } from '@mui/material/styles';
import axios from 'axios';
import CONFIG from '../../utils/Config';
import icons from '../../utils/Icons';
import {useClient} from "../../utils/context/ClientContext";


export const ProgressBar = (props) => {
  const [activeState, setActiveState] = useState(0);
  const [states, setStates] = useState();
  const loadingRef = createRef(null);
  const { tenant } = useClient();

  useEffect(() => {
    axios({
      url: CONFIG.URL + "public/order/getStates",
      method: "GET",
      headers: {
        'X-TenantID': tenant
      }
    }).then((res) => {
      if (loadingRef.current != null) {
        loadingRef.current.classList = "hidden";
      }
      setStates(res.data);
      setActiveState(props.orderState.state.id);
    });
  }, []);

  return (
    <>
      <StyledEngineProvider injectFirst>
        <div className="mt-6 px-6 py-6 text-sm md:text-base md:w-full mx-auto h-auto md:p-10 rounded-2xl border bg-opacity-60 backdrop-filter backdrop-blur-lg mb-3 shadow-lg bg-blue-100">
          {states ? (
            <Stepper
              activeStep={activeState}
              orientation={"vertical"}
              className="text-center"
            >
              {states.map((step) => (
                <Step key={step.id}>
                  <StepLabel>
                    <h3 className="font-primary-medium text-xs md:text-base">
                      {step.name}
                    </h3>
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          ) : (
            <div ref={loadingRef} className="">
              {icons.loading}
            </div>
          )}
        </div>
      </StyledEngineProvider>
    </>
  );
}
