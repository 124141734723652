import React, { useEffect, useState, createRef, useRef } from "react";
import icons from "../../utils/Icons";
import { useNavigate } from "react-router-dom";
import CartItem from "./CartItem";
import {
  SwipeableList,
  SwipeableListItem,
  SwipeAction,
  TrailingActions,
} from "react-swipeable-list";
import "react-swipeable-list/dist/styles.css";
import EmptyCart from "../../assets/images/EmptyCart.svg";
import { useCart } from "../../utils/context/CartContext";

const Cart = (props) => {
  const parentRef = useRef(null);
  const cartContext = useCart();
  const [totalAmount, setTotalAmount] = useState(0);
  const [productData, setProductData] = useState(props.productData);
  const trailingActions = (e) => (
    <TrailingActions>
      <SwipeAction
        destructive={true}
        onClick={() => {
          removeItem(e.product.id, e);
        }}
      >
        <div className="bg-red-500 text-center font-primary-semibold text-white">
          <h1 className="p-8">Delete</h1>
        </div>
      </SwipeAction>
    </TrailingActions>
  );
  const removeItem = (productId, e) => {
    const updatedItems = productData.filter(
      (item) => item.product.id !== productId
    );
    setProductData(updatedItems);
    props.removeItem(productId);
  };  
  useEffect(() => {
    let amount = 0;
    if (cartContext.cartItems !== null && cartContext.cartItems !== undefined && cartContext.cartItems.length > 0) {
      cartContext.cartItems.forEach((item) => {
        amount += item.amount;
      });
      setTotalAmount(amount);
    }
    }, [cartContext.cartItems, setTotalAmount]);
  const handleQty = (e) => {
    let type = e.target.getAttribute("id");
    let productId = e.target.dataset.product;
    let products = cartContext.cartItems;
    let count = 0;    
    if (products.length > 0) {
      products.forEach((f) => {
        if (f.product.id === parseInt(productId)) {
          count = f.quantity;
        }
      });
    }

    if (type === "increase") {
      count += 1;
    } else {
      if (count > 0) {
        count -= 1;
      }
    }
    let amount = 0;
    if (products.length > 0) {
      products.forEach((f) => {
        if (f.product.id === parseInt(productId)) {
          f.quantity = count;
          f.amount = count * f.rate;
        }
        amount += f.amount;
      });
      cartContext.setCart(products);
      setTotalAmount(amount);  
      props.updateProductData();    
    }
  };
  return (
    <>
      {cartContext.cartItems !== null && cartContext.cartItems !== undefined && cartContext.cartItems.length > 0 ? (
        <div
          className={
            "w-11/12 md:w-8/12 p-3 md:px-10 md:pt-6 rounded-2xl mb-3 shadow-lg mt-5 bg-white border min-h-96 flex flex-col items-center mx-auto"
          }
        >
          <div className="flex justify-between items-start">
            <h3 className="font-primary-bold text-heading text-lg my-3 items-center">
              Order Summary
            </h3>
          </div>
          <div className="w-full mx-auto px-1 md:px-3 items-center">
            <SwipeableList ref={parentRef}>
              {cartContext.cartItems.length > 0 ? (
                cartContext.cartItems.map((e) => {
                  return (
                    <SwipeableListItem
                      key={e.product.id}
                      trailingActions={trailingActions(e)}
                    >
                      <div id={e.product.id} className="my-1">
                        <CartItem
                          imageUrl={e.imageUrl}
                          categoryName={e.product.category.name}
                          productName={e.product.name}
                          rate={e.rate}
                          quantity={e.quantity}
                          rating={e.rating}
                          ratingCount={e.ratingCount}                      
                          icons={icons}
                          handleQty={handleQty}
                          productId={e.product.id}
                          amount={e.amount}
                          loading={false}
                        />
                        <hr className="my-1"/>
                      </div>
                    </SwipeableListItem>
                  );
                })
              ) : (
                <></>
              )}
            </SwipeableList>
          </div>
        </div>
      ) : (
        <div className="py-24  text-gray-800 font-primary-semibold text-center mx-auto my-4 md:my-10 rounded-2xl w-11/12 md:w-8/12">
          <img src={EmptyCart} className='w-80 h-80 mx-auto' alt="No Cart Items Found" />
          <h1 className=" text-xl ">Your cart is empty</h1>
          <h1
            className="text-sm mx-auto text-white w-fit  bg-green-500 rounded-xl py-2 px-4 my-3 cursor-pointer hover:opacity-95"
            onClick={() => (window.location = "/home")}
          >
            Go to Home
          </h1>
        </div>
      )}     
    </>
  );
};

export default Cart;
