import React, { useEffect, useState } from "react";
import discuss from "../../assets/images/Discuss.svg";
import axios from "axios";
import CONFIG from "../../utils/Config";
import icons from "../../utils/Icons";

export default function UserDetailsPopUp(props) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [name, setName] = useState("");
  useEffect(() => {
    setIsModalOpen(props.visibility);
  });
  const registerUser = (e) => {
    const tokenId = sessionStorage.getItem("tokenId");
    const fullName = sessionStorage.getItem("fullName");
    let tableId = sessionStorage.getItem("tableId");
    if (tableId == "undefined" || tableId == undefined) {
      tableId = 1;
    }
    let userData = {
      fullName: name,
      tableId: tableId,
    };
    if (
      tokenId === undefined ||
      tokenId == null ||
      tokenId.toString().length === 0
    ) {
      axios({
        url: CONFIG.URL + "user/register",
        data: userData,
        method: "POST",
        headers: {
          key: CONFIG.KEY,
          token: CONFIG.TOKEN,
        },
      })
        .then((res) => {
          userData = res.data;
          sessionStorage.setItem("tokenId", userData.tokenId);
          sessionStorage.setItem("fullName", userData.fullName);
          props.updateUserData(userData);
        })
        .catch((err) => {
          console.error(err);
        });
      e.preventDefault();
      props.handleVisibility(1);
    } else {
      userData = {
        fullName: fullName,
        tableId: tableId,
        tokenId: tokenId,
      };
      props.handleOrderPlacement(userData);
    }
    e.preventDefault();
    props.handleVisibility(1);
  };
  const handleInput = (e) => {
    setName(e.target.value);
  };
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    props.handleVisibility(1);
  };

  return (
    <>
      {isModalOpen && (
        <div
          id="progress-modal"
          className="w-full fixed top-0 right-0 bottom-0 left-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50"
          role="dialog"
          aria-modal="true"
        >
          <div className="w-10/12 lg:w-6/12 h-auto relative bg-white rounded-2xl shadow pt-4  md:pt-0">
            <button
              onClick={closeModal}
              className="absolute top-3 right-3 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
              type="button"
            >
              <svg
                className="w-4 h-4"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>

            <div className=" h-auto block md:flex justify-around items-center lg:mx-10 mt-10   ">
              <div className="w-full hidden md:block  md:w-5/12 lg:w-6/12 py-0 md:px-5 xl:px-10 ">
                <img
                  src={discuss}
                  alt="discuss"
                  className="w-96 md:mt-6 lg:mt-12 mx-auto"
                />
              </div>

              <div className="w-11/12 mx-auto md:w-7/12  lg:w-6/12 text-center mt-5 lg:mt-20 px-3 md:px-1 ">
                <h1 className=" w-full md:w-8/12  mx-auto text-sm md:text-base block md:flex justify-center items-center font-primary-regular md:font-primary-medium  text-blue-gray-900  text-wrap">
                  We keep your information private. What's your name so I can
                  help you better?
                </h1>

                <form>
                  <div className="mt-5 mb-3 md:mt-5 md:mb-5 lg:my-10 flex justify-center ">
                    <input
                      id="name"
                      type="text"
                      onChange={handleInput}
                      placeholder="Your name here"
                      className="h-8 md:h-10 border font-primary-regular my-0 text-sm md:text-base focus:outline-none focus:ring-none focus:border-2 focus:border-green-600 border-gray-300 rounded-tl-md rounded-bl-md md:rounded-tl-lg md:rounded-bl-lg hover:border-gray-900 cursor-pointer px-2 py-1  md:p-2"
                    />
                    <button
                      className="h-8 md:h-10 border my-0 border-green-600 font-primary-gotham-medium text-white p-1 md:p-3 bg-green-600 hover:bg-green-500 rounded-tr-md rounded-br-md md:rounded-tr-lg md:rounded-br-lg cursor-pointer"
                      onClick={registerUser}
                    >
                      {icons.arrowForward}
                    </button>
                  </div>
                </form>
              </div>
              <div className="w-full block md:hidden ">
                <img src={discuss} alt="discuss" className="w-48  mx-auto" />
              </div>
            </div>

            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1440 280"
              className="rounded-b-2xl  "
            >
              <path
                fill="#a2d9ff"
                fill-opacity="1"
                d="M0,96L26.7,101.3C53.3,107,107,117,160,138.7C213.3,160,267,192,320,208C373.3,224,427,224,480,218.7C533.3,213,587,203,640,192C693.3,181,747,171,800,186.7C853.3,203,907,245,960,229.3C1013.3,213,1067,139,1120,138.7C1173.3,139,1227,213,1280,229.3C1333.3,245,1387,203,1413,181.3L1440,160L1440,320L1413.3,320C1386.7,320,1333,320,1280,320C1226.7,320,1173,320,1120,320C1066.7,320,1013,320,960,320C906.7,320,853,320,800,320C746.7,320,693,320,640,320C586.7,320,533,320,480,320C426.7,320,373,320,320,320C266.7,320,213,320,160,320C106.7,320,53,320,27,320L0,320Z"
              ></path>
            </svg>
          </div>
        </div>
      )}
    </>
  );
}
