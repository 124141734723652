import React, { useRef, useEffect, useState } from 'react';
import QrScanner from 'qr-scanner';
import CloseIcon from '../../utils/icons/CloseIcon';
import CameraIcon from '../../utils/icons/CameraIcon';

export default function ProductScanner() {
    const videoRef = useRef(null);
    const [qrCodeResult, setQrCodeResult] = useState('');
    const [error, setError] = useState('');
    const [scanning, setScanning] = useState(false);
    const qrScannerRef = useRef(null);

    const startScanner = async () => {
        if (videoRef.current) {
            try {
                qrScannerRef.current = new QrScanner(
                    videoRef.current,
                    result => {
                        setQrCodeResult(result.data);
                        setScanning(false);
                        qrScannerRef.current.stop();
                        console.log("Scanning Success");
                    },
                    {
                        onDecodeError: err => console.warn(err),
                        highlightScanRegion: true,
                        highlightCodeOutline: true
                    }
                );

                const hasCamera = await QrScanner.hasCamera();
                if (hasCamera) {
                    await qrScannerRef.current.start();
                    console.log("Scanner started");
                    setScanning(true);
                } else {
                    setError('No camera found.');
                    console.error("No camera found");
                }
            } catch (e) {
                setError(`Unable to access the camera.`);
                console.error("Camera access error:", e);
            }
        }
    };

    const stopScanner = () => {
        if (qrScannerRef.current) {
            qrScannerRef.current.stop();
            console.log("Scanner stopped");
            setScanning(false);
        }
    };

    useEffect(() => {
        return () => {
            if (qrScannerRef.current) {
                qrScannerRef.current.stop();
                qrScannerRef.current.destroy();
                console.log("Scanner destroyed on unmount");
            }
        };
    }, []);

    const handleScanButtonClick = () => {
        if (!scanning) {
            setQrCodeResult('');
            setError('');
            startScanner();
        }
    };

    return (
        <>
            {!scanning && (
            <div className="w-11/12 md:w-8/12 my-5  mx-auto md:hidden h-56 md:h-72 grid content-center font-primary-regular bg-sky-50 border-2 text-center border-dashed border-sky-500 rounded-lg cursor-pointer">
                    <button
                        onClick={handleScanButtonClick}
                    >
                        <CameraIcon className="w-12 h-12 mx-auto stroke-sky-500 "/>
                        <span className="text-base">Click </span>
                        <span className="text-sky-500 text-sm md:text-base font-primary-semibold">
                            Here
                        </span>
                        <span className="text-base"> to start scan</span>
                    </button>
            </div>
            )}
            <div className={`relative w-11/12 md:w-8/12 h-72 mx-auto md:hidden border border-gray-200 rounded-lg flex flex-col justify-center items-center ${!scanning ? ' hidden' : 'block'}`}>
                <div className={`absolute top-0 w-full h-full flex justify-center items-center `}>
                    <video ref={videoRef} className="video-scanner"></video>
                    <button
                        onClick={stopScanner}
                        className="absolute top-1 right-1 px-1 py-1"
                    >
                        <CloseIcon className="w-10 h-10 ml-auto font-primary-semibold text-gray-400 fill-current rounded-lg p-2 cursor-pointer " />
                    </button>
                </div>

                {qrCodeResult && <p>QR Code Result: {qrCodeResult}</p>}
                {error && <p className="error">{error}</p>}
            </div>
        </>
       
    );
}
