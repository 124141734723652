import React, { useEffect, useState } from "react";
import Sheet from "react-modal-sheet";
import OfferCard from "../elements/OfferCard";
import axios from "axios";
import CONFIG from "../../utils/Config";
import { useClient } from "../../utils/context/ClientContext";

export default function OffersPopup({visibility, amount, handleVisibility}) {
  const clientContext = useClient();
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [offers, setOffers] = useState([]);
  useEffect(() => {
    setIsModalOpen(visibility);
    axios({
      url: CONFIG.URL + "public/offers/getOffers?amount="+amount,
      method: 'GET',
      headers: {
        'X-TenantID': clientContext.tenant
      }
    }).then((res) => {
      setOffers(res.data);
    }).catch((err) => {
      console.error(err);
    })
  }, []);
  const openModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
    handleVisibility();
  };

  return (
    <>
      {isModalOpen && (
        <div>
          <Sheet
            isOpen={isModalOpen}
            onClose={() => closeModal()}
            detent="content-height"
            className="w-full md:w-8/12 mx-auto"
          >
            <Sheet.Container
              className="bg-sky-50 "
              layoutScroll={true}
            >
              <Sheet.Header />
              <h1 className="text-center font-primary-bold text-xl">
                Available Offer's
              </h1>
              <hr className="px-4 mt-2" />
              <Sheet.Content style={{ height: "900px" }}>
                <div
                  className="overflow-y-auto "
                  style={{ maxHeight: "calc(100vh - auto)" }}
                >
                  <div className=" overflow-y-auto scrollbar-hide ">
                    <div className="grid grid-auto-fit gap-3 w-10/12 mx-auto my-5 scrollbar-hide ">
                      {offers !== null && offers !== undefined && offers.length > 0 ? (
                        offers.map((data) => (
                          <OfferCard offerData={data} key={data.id} closeModal={closeModal}/>
                        )
                        )) : <></>
                      }                      
                    </div>
                  </div>
                </div>
              </Sheet.Content>
            </Sheet.Container>
            <Sheet.Backdrop />
          </Sheet>
        </div>
      )}
    </>
  );
}