import React, { useState } from 'react'
import HambergerIcon from '../../utils/icons/HambergerIcon'
import CloseIcon from '../../utils/icons/CloseIcon'
import LOGO from '../../assets/images/CleFactura.png'
import { NavLink } from 'react-router-dom';
import HomeIcon from '../../utils/icons/HomeIcon';
import RestaurantsIcon from '../../utils/icons/RestaurantsIcon';
import OrderIcon from '../../utils/icons/OrderIcon';
import CartIcon from '../../utils/icons/CartIcon';
import ContactIcon from '../../utils/icons/ContactIcon';
import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import { useCart } from '../../utils/context/CartContext';


export const SideNavbar = () => {
    const [menuOpen,setOpenMenu] = useState(false);
    const [activeLink, setActiveLink] = useState();

    const menuList = [
        {label:"Home",icon:<HomeIcon className="w-5 h-5"/>,link:"/home"},
        {label:"Restaurants",icon:<RestaurantsIcon className='w-5 h-5' />,link:"/clients"},
        {label:"Orders",icon:<OrderIcon className="w-5 h-5"/>,link:"/orders"},
        {label:"Cart",icon:<CartIcon className="w-5 h-5"/>,link:"/viewCart"},
        {label:"Ping",icon:<ContactIcon className="w-5 h-5"/>,link:"/contactUs"},
    ]
    const menuContent = [
        {label:"Terms & Service",link:"https://tforte.in/terms"},
        {label:"Cancellation Policy",link:"https://tforte.in/privacy"},
        {label:"Privacy & Policy",link:"https://tforte.in/privacy"},
        {label:"Contact Us",link:"mailto: hello@clefactura.com"}
    ]
    const handleMenuOpen = ()=>{
        setOpenMenu(!menuOpen)
    }
    const handleActive = (link) =>{
        setActiveLink(link)
    }

  return (
    <div>
      <button onClick={handleMenuOpen}>
        {!menuOpen ? (<HambergerIcon className="w-7 h-7 text-gray-800 fill-current" />) : (<CloseIcon className="absolute right-5 md:right-10 top-3 md:top-5 z-50 w-10 md:w-12 h-10 md:h-12 text-gray-500 fill-current" />)}
      </button>
      {menuOpen && (<>
        <div className='absolute left-0 top-0 w-full h-svh backdrop-blur-0 bg-black/30'></div>
        <div className={`absolute right-0 top-0 w-full md:w-4/12 xl:w-3/12 py-16 md:pt-20 px-5 md:px-10 bg-sky-50 md:bg-white h-lvh transition-all ease-in-out duration-200 overflow-y-auto ${menuOpen ? "translate-x-0 " : "-translate-x-full"}`}>
          <div className='w-full mx-auto px-5 md:min-h-full flex flex-col justify-between bg-white  md:bg-none rounded-2xl  md:rounded-none shadow md:shadow-none'>

            <div>
              {menuList.map((menu, index) => (<NavLink key={index} to={menu.link} className={`${activeLink === menu.link ? 'text-primary-blue' : 'text-gray-700'}`}
                onClick={() => handleActive(menu.link)}>
                <div className='flex gap-3 items-center py-3 border-b md:border-none border-gray-100'>
                  {menu.icon}
                  <span>{menu.label}</span>
                </div>

              </NavLink>))}


            </div>
            <div className='border-t  hidden md:block border-gray-200 py-2.5'>
              <p className="text-gray-800 font-primary-bold">
                For Enquiry
              </p>
              <p className="text-gray-700 font-primary-regular cursor-pointer" onClick={() => window.location = "mailto:hello@clefactura.com"}>
                hello@clefactura.com
              </p>
            </div>
          </div>
          <div className='w-full md:hidden mx-auto py-2 px-3 bg-white rounded-2xl shadow my-5'>
            {menuContent.map((menu, index) => (<NavLink key={index} onClick={() => window.location = menu.link} className={`${activeLink === menu.link ? 'text-primary-blue' : 'text-gray-700'}`} >
              <div className=' py-2 px-3'>
                <span>{menu.label}</span>
              </div>
            </NavLink>))}
          </div>
          <div class="flex place-self-end gap-2.5 mt-20 items-center border-t border-gray-200 py-5 md:hidden">
            <img src={LOGO} alt="Clè-Factura Logo" className="footer-logo  cursor-pointer" onClick={() => window.location = "https://clefactura.tforte.in"} />
            <div className='flex flex-col justify-between items-start'>
              <div class="flex">
                <h2 class="text-lg font-primary-semibold">Clè-Factura</h2>
                <sup class="uppercase text-xs font-primary-semibold"
                >TM</sup
                >
              </div>
              <p class="text-gray-600 font-primary-light text-xs">Your companion in scaling your Business</p>
            </div>
          </div>
        </div></>
      )}
    </div>
  )
}
