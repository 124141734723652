import { createContext, useContext, useEffect, useState } from "react";

const ClientContext = createContext();

export const ClientContextProvider = ({children}) => {            
    const [tenant, settenant] = useState(() => {
        const tenant = sessionStorage.getItem('tenant');
        if(tenant !== undefined && tenant !== null){
            return tenant;
        }else{
            return null;
        }
    });
    const [client, setclient] = useState(() => {
        const client = sessionStorage.getItem('client');
        if(client !== undefined && client !== null){
            return JSON.parse(client);
        }else{
            return null;
        }
    });

    const [name, setname] = useState(() => {
        const name = sessionStorage.getItem('name');
        if(name !== undefined && name !== null){
            return name;
        }else{
            return null;
        }
    });     

    useEffect(() => {                              
        sessionStorage.setItem('tenant', tenant); 
        sessionStorage.setItem('name', name);  
        sessionStorage.setItem('client', JSON.stringify(client));               
    }, [tenant, name, client]);

    const setTenant  = (tenant) => {        
        settenant(tenant);
    }
    const setName  = (name) => {        
        setname(name);
    }
    const setClient  = (client) => {        
        setclient(client);
    }

    return <ClientContext.Provider value={{tenant, name, client, setTenant, setClient, setName}}>{children}</ClientContext.Provider>
}

export const useClient = () => {
    return useContext(ClientContext);
}
