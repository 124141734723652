import React, { useState } from "react";
import Filter from "./Filters";

const Header = ({ handleProductData, handleAdvancedFilterVisibility }) => {
  const [filter, setAppliedFilter] = useState(null);

  const handleProductFilter = (type, id, selected) => {
    let tempFilter;
    if (!filter || filter === undefined) {
      tempFilter = {};
    } else {
      tempFilter = filter;
    }
    let ids = [];    
    switch (type) {
      case 1:
        if(tempFilter.category != undefined){
          ids = tempFilter.category;
        }
        if(selected === true && !ids.includes(id)){
          ids.push(id);
        }else{
          ids = ids.filter((e) => {
            return parseInt(e) !== parseInt(id);
          })
        }        
        tempFilter.category = ids;
        break;
      case 2:
        if(tempFilter.subCategory != undefined){
          ids = tempFilter.subCategory;
        }
        if(selected === true){
          ids.push(id);
        }else{
          ids = ids.filter((e) => {
            return parseInt(e) !== parseInt(id);
          })
        }  
        tempFilter.subCategory = ids;
        break;
      case 3:
        if(tempFilter.timeId != undefined){
          ids = tempFilter.timeId;
        }
        if(selected === true){
          ids.push(id);
        }else{
          ids = ids.filter((e) => {
            return parseInt(e) !== parseInt(id);
          })
        }  
        tempFilter.timeId = ids;
        break;
      default:
        break;
    }
    setAppliedFilter(tempFilter);
    let data = {
      filter: tempFilter,
    };
    handleProductData(data);    
  }; 

  return (
    <div className="w-full mx-auto">      
      <div className="w-full  mx-auto">
        <Filter handleProductFilter={handleProductFilter} handleAdvancedFilterVisibility={handleAdvancedFilterVisibility} />
      </div>
    </div>
  );
};

export default Header;
