import React from 'react'
import { Navigate } from 'react-router-dom';
import { useClient } from './ClientContext';

const RequireClient = ({ children }) => {
    const auth = useClient();   
    if(!auth.client){              
        return <Navigate to='/clients'/>;
    }
  return children;
}

export default RequireClient