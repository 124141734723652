import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import icons from "../../utils/Icons";

const SECOND = 1000;
const MINUTE = SECOND * 60;
const HOUR = MINUTE * 60;
const DAY = HOUR * 24;
let distance = 7200000;

const ShiftingCountdown = (props) => {
  const intervalRef = useRef(null);
  const [remaining, setRemaining] = useState({
    hours: props.hrs,
    minutes: props.mins,
    seconds: props.sec,
  });
  useEffect(() => {
    intervalRef.current = setInterval(handleCountdown, 1000);
    return () => clearInterval(intervalRef.current || undefined);
  }, []);

  const handleCountdown = () => {  
    let distance = props.distance;
    let type = props.type; 
    if(type === 1){      
      distance = sessionStorage.getItem("sessionTime");      
      if(distance == "NaN" || distance < 0){
        distance = 7200000;
      }
    }       
    const hours = Math.floor((distance % DAY) / HOUR)
      .toString()
      .padStart(2, "0");
    const minutes = Math.floor((distance % HOUR) / MINUTE)
      .toString()
      .padStart(2, "0");
    let seconds = Math.floor((distance % MINUTE) / SECOND)
      .toString()
      .padStart(2, "0");

    distance -= 1000;

    if(type == 1)    {
      sessionStorage.setItem("sessionTime",distance);
    }
    setRemaining({
      hours,
      minutes,
      seconds,
    });
  };

  return (
    
      <div className="w-full  flex justify-center items-center gap-2">
        {icons.clock}
        <CountdownItem num={remaining.hours} text="hours" />:
        <CountdownItem num={remaining.minutes} text="minutes" />: 
        <CountdownItem num={remaining.seconds} text="seconds" />         
      </div>
  );
};

const CountdownItem = ({ num, text }) => {
  return (
    <div className="font-mono flex flex-col gap-3 items-center justify-center my-auto">
      <div className="w-full text-center relative overflow-hidden">
        <AnimatePresence mode="popLayout">
          <motion.span
            key={num}
            initial={{ y: "100%" }}
            animate={{ y: "0%" }}
            exit={{ y: "-100%" }}
            transition={{ ease: "backIn", duration: 0.75 }}
            className="block text-sm md:text-base text-black font-medium"
          >
            {num} 
          </motion.span>
        </AnimatePresence>
      </div>      
    </div>
  );
};

export default ShiftingCountdown;